// DropdownList.js
import React, { useState } from 'react';
import DropdownItem from './DropdownItem';
import data from './data';

const DropdownList = () => {
    const [category, setCategory] = useState('All'); // Default to showing all items

    // Filter the data based on the selected category
    const filteredData = category === 'All' ? data : data.filter(item => item.category === category);

    // Function to sort data by title
    const sortData = () => {
        const sortedData = [...filteredData];
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        return sortedData;
    };

    return (
        <div className="dropdown-list">
            <select value={category} onChange={e => setCategory(e.target.value)} className="cat-nut">
                <option value="All">Toate Categoriile</option>
                {/* Map over unique categories from data and create options */}
                {[...new Set(data.map(item => item.category))].map(category => (
                    <option key={category} value={category}>
                        {category}
                    </option>
                ))}
            </select>
            {sortData().map(item => (
                <DropdownItem key={item.id} item={item} />
            ))}
        </div>
    );
};

export default DropdownList;
