import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Navbar from "./menu/menu";
import Locatii from "./pages/locatii";
import Meniu from "./pages/meniu";
import Soon from "./pages/soon";
import Admin from "./pages/admin";
import PlotChart from "./pages/plot";
import Conta from "./pages/conta";
import Item from "./pages/Item";
import ContaMain from "./pages/contaMain";
import Conta1 from "./pages/conta1";
import Conta2 from "./pages/conta2";
import Login from "./pages/login";
import Retetar from "./pages/retetar";
import Nutritionale from "./pages/nutritionale";
import Franciza from "./pages/franciza";



const Root = () => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    const login = () => {
        setIsAuthenticated(true);
    };

    // const logout = () => {
    //     setIsAuthenticated(false);
    // };

    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/franciza" element={<App />} />
                    <Route path="/locatii" element={<App />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route
                        exact
                        path="/login"
                        element={<Login isAuthenticated={isAuthenticated} login={login}  />}
                    />
                    <Route
                        exact
                        path="/conta/batistei"
                        element={
                            isAuthenticated ? <Conta /> : <Login isAuthenticated={isAuthenticated} login={login}  />
                        }
                    />
                    <Route
                        exact
                        path="/conta/italiana"
                        element={
                            isAuthenticated ? <Conta1 /> : <Login isAuthenticated={isAuthenticated} login={login} />
                        }
                    />
                    <Route
                        exact
                        path="/conta/lahovari"
                        element={
                            isAuthenticated ? <Conta2 /> : <Login isAuthenticated={isAuthenticated} login={login} />
                        }
                    />


                    <Route
                        exact
                        path="/statistici"
                        element={
                            isAuthenticated ? <PlotChart /> : <Login isAuthenticated={isAuthenticated} login={login} />
                        }
                    />

                    <Route
                        exact
                        path="/retetar"
                        element={
                            isAuthenticated ? <Retetar /> : <Login isAuthenticated={isAuthenticated} login={login} />
                        }
                    />

                    {/*Testing Pages*/}
                    <Route path="/retetar" element={<Retetar/>} />
                    <Route path="/conta/batistei" element={<Conta />} />
                    <Route path="/conta/italiana" element={<Conta1 />} />
                    <Route path="/conta/lahovari" element={<Conta2/>} />
                    <Route path="/statistici" element={<PlotChart/>} />

                    <Route path="/meniu" element={<Meniu />} />
                    <Route path="/conta/lahovari" element={<Conta2 />} />
                    <Route path="/conta/locatii" element={<ContaMain />} />
                    <Route path="/microprajitorie" element={<Soon />} />
                    <Route path="/contact" element={<App />} />
                    <Route path="/retetar" element={<Retetar />} />
                    <Route path="/valori/nutritionale" element={<Nutritionale />} />
                    <Route path="/" element={<App />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();