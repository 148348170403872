// DropdownItem.js
import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import {fontWeight} from "plotly.js/src/plots/plots";

const DropdownItem = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown-item">
            <div className="dropdown-toggle" onClick={toggleDropdown}>
                {item.title} {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isOpen && (
                <div className="additional-info">
                    <p className="ingredient">{item.ingr}</p>
                    <p className="gramaj">{item.gr}</p>
                    <br/>
                    <p className="ingredients">{item.ingredients}</p>
                    <br/>
                    {item.additionalInfo.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                    <br/>
                    <p className="alergeni">{item.alergens}</p>
                </div>
            )}
        </div>
    );
};

export default DropdownItem;
