import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import loadingVid from './assets/Untitled.mp4';
import './App.css';

import Menu from './menu/menu';
import Header from './pages/header';
import Locatii from './pages/locatii';
import Contact from './pages/contact';
import Loading from './pages/loading';

function App() {
    const [animationPlayed, setAnimationPlayed] = useState(false);

    useEffect(() => {
        // Check if the animation has been played from localStorage
        if (localStorage.getItem('animationPlayed')) {
            setAnimationPlayed(true);
        } else {
            // If the animation hasn't been played, wait for 3 seconds and then set the flag
            const timer = setTimeout(() => {
                setAnimationPlayed(true);
                localStorage.setItem('animationPlayed', 'true');
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, []);

    return (
        <div className={`App ${animationPlayed ? 'website-content' : ''}`}>
            {animationPlayed ? (
                <>
                    <Menu />
                    {/*<Loading/>*/}
                    <Header />
                    <Locatii />
                    <Contact />
                </>
            ) : (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: -1,
                    }}
                >
                    <video
                        autoPlay
                        muted
                        playsInline
                        style={{
                            width: '80%',
                            height: '80%',
                            objectFit: 'cover',
                        }}
                    >
                        <source src={loadingVid} type="video/mp4" />
                    </video>
                </div>
            )}
        </div>
    );
}

export default App;
