import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, useLocation, useNavigate} from 'react-router-dom';
import Navbar from "../menu/menu";
import "./login.css"
import "./admin.css"

const Login = ({ isAuthenticated, login, logout }) => {


    const [showPasswordLogin, setShowPasswordLogin] = useState(false);

    const handleTogglePassword = () => {
        setShowPasswordLogin(!showPasswordLogin);
    };

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [id, setId] = useState('');
    const [pass, setPass] = useState('');
    const [id1, setId1] = useState('');
    const [pass1, setPass1] = useState('');
    const [id2, setId2] = useState('');
    const [pass2, setPass2] = useState('');
    const [id3, setId3] = useState('');
    const [pass3, setPass3] = useState('');
    const [id4, setId4] = useState('');
    const [pass4, setPass4] = useState('');
    const [data, setData] = useState('');
    const navigate = useNavigate();

    const [location, setLocation] = useState(useLocation().pathname);
    // console.log(location.pathname)

   // location.pathname === '/specific-url';

    useEffect(() => {
        fetch('https://roasted.ro/login.php', {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData);
                if (jsonData[0]?.username) { // Add a null check before accessing the property
                    setId(jsonData[0].username);
                    // console.log(username);
                }
                if (jsonData[0]?.pass) { // Add a null check before accessing the property
                    setPass(jsonData[0].pass);
                    // console.log(pass);
                }
                if (jsonData[1]?.username) { // Add a null check before accessing the property
                    setId1(jsonData[1].username);
                    // console.log(username);
                }
                if (jsonData[1]?.pass) { // Add a null check before accessing the property
                    setPass1(jsonData[1].pass);
                    // console.log(pass);
                }
                if (jsonData[2]?.username) { // Add a null check before accessing the property
                    setId2(jsonData[2].username);
                    // console.log(username);
                }
                if (jsonData[2]?.pass) { // Add a null check before accessing the property
                    setPass2(jsonData[2].pass);
                    // console.log(pass);
                }
                if (jsonData[3]?.username) { // Add a null check before accessing the property
                    setId3(jsonData[3].username);
                    // console.log(username);
                }
                if (jsonData[3]?.pass) { // Add a null check before accessing the property
                    setPass3(jsonData[3].pass);
                    // console.log(pass);
                }
                if (jsonData[4]?.username) { // Add a null check before accessing the property
                    setId4(jsonData[4].username);
                    // console.log(username);
                }
                if (jsonData[4]?.pass) { // Add a null check before accessing the property
                    setPass4(jsonData[4].pass);
                    // console.log(pass);
                }
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handleLogin = () => {
        // Perform login logic, e.g., calling an API, validating credentials, etc.
        // Assuming successful login sets isAuthenticated to true\
        if ((username === id && password === pass) && (location == '/conta/batistei' || location == '/login' )) {
            login();
            navigate('/conta/batistei');
        }
        else if ((username === id1 && password === pass1) && (location == '/conta/italiana' || location == '/login' )) {
            login();
            navigate('/conta/italiana');
        }
        else if ((username === id2 && password === pass2) && (location == '/conta/lahovari' || location == '/login' )) {
            login();
            navigate('/conta/lahovari');
        }
        else if ((username === id3 && password === pass3) && (location == '/statistici' || location == '/login' )) {
            login();
            navigate('/statistici');
        }
        else if ((username === id4 && password === pass4) && (location == '/retetar' || location == '/login' )) {
            login();
            navigate('/retetar');
        }
        else{
            alert("Parola sau Id Gresite")
            console.log(username)
            console.log(password)
        }
    };

    if (isAuthenticated) {
        navigate('/conta/batistei');
        return null;
    }

    return (
            <div>
                <Navbar/>
                <div className="admin-login">
                    <div className="login-title">
                        Login Page
                    </div>
                    <div className="login-line"></div>
                    <div className="login-content-conta">
                        <p className="login-offset">
                            <label className="padding-label1">
                                Id:
                            </label>
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="margin-label-login"
                            />
                        </p>
                        <p className="login-offset">
                            <label className="padding-label">
                                Pass:
                            </label>
                            <input
                                type={showPasswordLogin ? 'text' : 'password'}
                                placeholder="Parola"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="margin-label-login"
                            />
                        </p>
                        <p className="login-offset">
                            <button className="login-btn" onClick={handleTogglePassword}>{showPasswordLogin ? 'Hide pass' : 'Show pass'}</button>

                            <button className="login-btn" onClick={handleLogin}>Login</button>
                        </p>
                    </div>
                </div>
        </div>
    );
};

export default Login