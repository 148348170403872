import Navbar from "../menu/menu";
import "./retetar.css";
import DropdownButton from "./dropdown";
import React, {useEffect, useState} from "react";
import Plot from "react-plotly.js";
import {json} from "react-router-dom";

export default function Retetar() {
    const options = ['flavourS', 'flavourM', 'flavourL', 'flavourXL', 'cappuccinoS', 'cappuccinoM', 'cappuccinoL', 'cappuccinoXL',
        'latteS', 'latteM', 'latteL', 'latteXL', 'mochacinoM', 'mochacinoL', 'darkMochacinoM', 'darkMochacinoL', 'whiteMochacinoM',
        'whiteMochacinoL', 'flatS', 'flatM', 'espresso', 'dubluEspresso', 'cortado', 'americanoS', 'americanoM', 'americanoL', 'americanoXL',
        'buzzShot', 'ciocolataCaldaM', 'ciocolataCaldaL', 'chaiLatteM', 'chaiLatteL', 'machaM', 'machaL',
        'sweetMachaM', 'sweetMachaL', 'chicoryM', 'chicoryL', 'ceaiM', 'ceaiL', 'fogTeaM', 'fogTeaL', 'turmericLatteM', 'turmericLatteL',
        'espressoTonic', 'iceCaramelGelatoM', 'iceCaramelGelatoL', 'iceCafeLatteM', 'iceCafeLatteL', 'iceMachaLatteM', 'iceMachaLatteL',
        'iceChaiLatteM', 'iceChaiLatteL', 'iceCafeLatteFlavouredM', 'iceCafeLatteFlavouredL', 'iceCoffeeM', 'iceCoffeeL'];

    const prices = [8, 10, 12, 16, 7, 9, 11, 15, 7, 9, 11, 15, 12, 14, 12, 14, 12, 14, 12, 14, 7, 9, 7, 7, 9, 11, 15, 17, 12, 14, 12, 14, 12, 14, 12, 14,
    12, 14, 7, 9, 12, 14, 12, 14, 14, 18, 20, 16, 18, 18, 20, 18, 20, 17, 19, 16, 18]

    function calculateVAT(price) {
        const vatRate = 19;
        const vatRatePercentage = (1 + vatRate/100);
        const vatAmount = (price - (price / vatRatePercentage)).toFixed(2);
        return vatAmount;
    }


    const costTva = prices.map(price => calculateVAT(price));

    // const costTva = [1.21, 1.58, 1.97, 2.63, 1.12, 1.35, 1.62, 2.38, 1.12, 1.35, 1.62,
    //     2.38, 1.97, 2.11, 1.97, 2.11, 1.97, 2.11, 1.97, 2.11, 1.12, 1.35, 1.12, 1.12, 1.35, 1.62, 2.38, 2.56, 1.97, 2.11, 1.97, 2.11, 1.97, 2.11, 1.97, 2.11, 1.97, 2.11, 1.12, 1.35, 1.97, 2.11, 1.97, 2.11, 2.11, 2.72, 3.09, 2.63, 2.72, 2.72, 3.09, 2.72, 3.09, 2.56, 2.86, 2.63, 2.72]

    const productRealCost = new Map();

    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const price = prices[i];

        productRealCost.set(option, price);
    }

    const productTVA = new Map();

    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const tva = costTva[i];

        productTVA.set(option, tva);
    }

    const [preturi, setPreturi] = useState()
    const [optionsData, setOptionsData] = useState()
    let dataAndOraMap = new Map()



    useEffect(() => {
        fetch('https://roasted.ro/retete.php',  {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setPreturi(jsonData);

                // Create a dataAndOraMap after the data is fetched
                const dataAndOraMap = jsonData.map(recipe => ({
                    data: recipe.data,
                    ora: recipe.ora
                }));

                // Use the dataAndOraMap as needed
                // console.log("Data and Ora Map:", dataAndOraMap);

                // Other stuff you want to do with the fetched data
                // ...

            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);
    //
    // const dataAndOraMap = preturi.map(recipe => ({
    //     data: recipe.data,
    //     ora: recipe.ora
    // }));


    const [lapteValue, setLapteValue] = useState(0.0059);
    const [cafeaValue, setCafeaValue] = useState(0.070);
    const [siropValue, setSiropValue] = useState(0.048);
    const [inghetataValue, setInghetataValue] = useState(0.035);
    const [gheataValue, setGheataValue] = useState(0.003);
    const [friscaValue, setFriscaValue] = useState(0.021);
    const [toppingValue, setToppingValue] = useState(0.033);
    const [apaTonicaValue, setApaTonicaValue] = useState(0.006);
    const [pahar4ozValue, setPahar4ozValue] = useState(0.142);
    const [capac4ozValue, setCapac4ozValue] = useState(0.082);
    const [pahar7ozValue, setPahar7ozValue] = useState(0.173);
    const [capac7ozValue, setCapac7ozValue] = useState(0.097);
    const [pahar8ozValue, setPahar8ozValue] = useState(0.22);
    const [capac8ozValue, setCapac8ozValue] = useState(0.10);
    const [pahar12ozValue, setPahar12ozValue] = useState(0.3);
    const [pahar16ozValue, setPahar16ozValue] = useState(0.4);
    const [pahar22ozValue, setPahar22ozValue] = useState(0.47);
    const [capac12to22ozValue, setCapac12to22ozValue] = useState(0.1213);
    const [paharFrapeMValue, setPaharFrapeMValue] = useState(0.32);
    const [paharFrapeLValue, setPaharFrapeLValue] = useState(0.37);
    const [capacFrapeMLValue, setCapacFrapeMLValue] = useState(0.12);
    const [logoValue, setLogoValue] = useState(0.17);
    const [baghetaZaharAlbValue, setBaghetaZaharAlbValue] = useState(0.075);
    const [baghetaZaharBrunValue, setBaghetaZaharBrunValue] = useState(0.075);
    const [paletinaValue, setPaletinaValue] = useState(0.014);
    const [bomboanaValue, setBomboanaValue] = useState(0.095);
    const [consumabileNecuantificabileValue, setConsumabileNecuantificabileValue] = useState(0.10);
    const [paieValue, setPaieValue] = useState(0.15);
    const [ciocolataAlbaValue, setciocolataAlbaValue] = useState(0.03);
    const [ciocolataNeagraValue, setciocolataNeagraValue] = useState(0.03);
    const [ciocolataLapteValue, setciocolataLapteValue] = useState(0.03);
    const [chaiValue, setChaiValue] = useState(0.075);
    const [machaValue, setMachaValue] = useState(0.41);
    const [sweetMachaValue, setSweetMachaValue] = useState(0.118);
    const [chicoryValue, setChicoryValue] = useState(0.15);
    const [ceaiValue, setCeaiValue] = useState(0.9);
    const [turmericValue, setTurmericValue] = useState(0.072);


    const [selectedRecipe, setSelectedRecipe] = useState(null);

    const handleSelectChangeDataAndOra = (event) => {
        setSelectedOptionData(event.target.value);
        const [selectedData, selectedOra] = event.target.value.split('/');
        const selectedData1 = selectedData.toString().slice(6,(selectedData.toString().length-1))
        const selectedOra1 = selectedOra.toString().slice(6)
        // console.log(selectedData1)
        // console.log(selectedOra1)
        let foundRecipe = null;
        for (const recipe of preturi) {
            // console.log(recipe.data.toString())
            // console.log(recipe.ora.toString())
            if (recipe.data.toString() === selectedData1 && recipe.ora.toString() === selectedOra1) {
                foundRecipe = recipe;
                break;
            }
        }
        setCafeaValue(foundRecipe.cafea)
        setLapteValue(foundRecipe.lapte)
        setSiropValue(foundRecipe.sirop)
        setInghetataValue(foundRecipe.inghetata)
        setGheataValue(foundRecipe.gheata)
        setFriscaValue(foundRecipe.frisca)
        setToppingValue(foundRecipe.topping)
        setApaTonicaValue(foundRecipe.apa_tonica)
        setLogoValue(foundRecipe.logo)
        setCapac4ozValue(foundRecipe.capac4)
        setPahar7ozValue(foundRecipe.pahar7)
        setCapac7ozValue(foundRecipe.capac7)
        setPahar8ozValue(foundRecipe.pahar8)
        setCapac8ozValue(foundRecipe.capac8)
        setBaghetaZaharAlbValue(foundRecipe.zahar_alb)
        setBaghetaZaharBrunValue(foundRecipe.zahar_brun)
        setPaletinaValue(foundRecipe.paletina)
        setBomboanaValue(foundRecipe.bomboane)
        setConsumabileNecuantificabileValue(foundRecipe.necuantificabile)
        setPahar12ozValue(foundRecipe.pahar12)
        setCapac12to22ozValue(foundRecipe.capac12)
        setPahar16ozValue(foundRecipe.pahar16)
        setPahar22ozValue(foundRecipe.pahar22)
        setPaharFrapeMValue(foundRecipe.pahar_frappe_mediu)
        setPaharFrapeLValue(foundRecipe.pahar_frappe_mare)
        setCapacFrapeMLValue(foundRecipe.capac_frappe)
        setciocolataLapteValue(foundRecipe.cioco_lapte)
        setciocolataNeagraValue(foundRecipe.cioco_neagra)
        setciocolataAlbaValue(foundRecipe.cioco_alba)
        setChaiValue(foundRecipe.chai)
        setMachaValue(foundRecipe.macha)
        setSweetMachaValue(foundRecipe.sweet_macha)
        setChicoryValue(foundRecipe.chicory)
        setCeaiValue(foundRecipe.ceai)
        setTurmericValue(foundRecipe.turmeric)
        setPaieValue(foundRecipe.pai)
        // console.log(foundRecipe)
    };



    const [selectedOptionData, setSelectedOptionData] = useState(options[0]);
    const [selectedOptionOra, setSelectedOptionOra] = useState(options[0]);
    const [listOfPrices, setListOfPrices] = useState(0)

    // console.log(preturi)



    const now = new Date();
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    const currentDate = new Date().toISOString().split('T')[0];
    const currentTime = hour+":"+minute+":"+second
    const [data, setData] = useState(currentDate)
    const [ora, setOra] = useState(currentTime)
    const [cafenea, setCafenea] = useState()
    const [cafea, setCafea] = useState()
    const [lapte, setLapte] = useState()
    const [sirop, setSirop] = useState()
    const [inghetata, setInghetata] = useState()
    const [gheata, setGheata] = useState()
    const [frisca, setFrisca] = useState()
    const [topping, setTopping] = useState()
    const [apaTonica, setApaTonica] = useState()
    const [pahar4Oz, setPahar4Oz] = useState()
    const [capac4Oz, setCapac4Oz] = useState()
    const [pahar7Oz, setPahar7Oz] = useState()
    const [capac7Oz, setCapac7Oz] = useState()
    const [pahar8Oz, setPahar8Oz] = useState()
    const [capac8Oz, setCapac8Oz] = useState()
    const [pahar12Oz, setPahar12Oz] = useState()
    const [capac12Oz, setCapac12Oz] = useState()
    const [pahar16Oz, setPahar16Oz] = useState()
    const [pahar22Oz, setPahar22Oz] = useState()
    const [paharFrappeM, setPaharFrappeM] = useState()
    const [paharFrappeL, setPaharFrappeL] = useState()
    const [capacFrappeML, setCapacFrappeML] = useState()
    const [logo, setLogo] = useState()
    const [macha, setMacha] = useState()
    const [sweetMacha, setSweetMacha] = useState()
    const [chai, setChai] = useState()
    const [chicory, setChicory] = useState()
    const [turmeric, setTurmeric] = useState()
    const [ceai, setCeai] = useState()
    const [ciocoLapte, setCiocoLapte] = useState()
    const [ciocoNeagra, setCiocoNeagra] = useState()
    const [ciocoAlba, setCiocoAlba] = useState()
    const [zaharAlb, setZaharAlb] = useState()
    const [zaharBrun, setZaharBrun] = useState()
    const [paletina, setPaletina] = useState()
    const [bomboana, setBomboana] = useState()
    const [necuantificabile, setNecuantificabile] = useState()
    const [paie, setPaie] = useState()


    const changeData = e => {
        setData(e.target.value)
    }

    const changeOra = e => {
        setOra(e.target.value)
    }

    const changeCafenea = e => {
        setCafenea(e.target.value)
    }


    const changeCafea = e => {
        setCafea(e.target.value)
    }

    const changeLapte = e => {
        setLapte(e.target.value)
    }

    const changeSirop = e => {
        setSirop(e.target.value)
    }

    const changeInghetata = e => {
        setInghetata(e.target.value)
    }

    const changeGheata = e => {
        setGheata(e.target.value)
    }

    const changeFrisca = e => {
        setFrisca(e.target.value)
    }

    const changeTopping = e => {
        setTopping(e.target.value)
    }

    const changeApaTonica = e => {
        setApaTonica(e.target.value)
    }

    const changePahar4Oz = e => {
        setPahar4Oz(e.target.value)
    }

    const changeCapac4Oz = e => {
        setCapac4Oz(e.target.value)
    }

    const changeCapac7Oz = e => {
        setCapac7Oz(e.target.value)
    }

    const changeCapac8Oz = e => {
        setCapac8Oz(e.target.value)
    }

    const changeCapac12Oz = e => {
        setCapac12Oz(e.target.value)
    }

    const changePahar7Oz = e => {
        setPahar7Oz(e.target.value)
    }

    const changePahar8Oz = e => {
        setPahar8Oz(e.target.value)
    }

    const changePahar12Oz = e => {
        setPahar12Oz(e.target.value)
    }

    const changePahar16Oz = e => {
        setPahar16Oz(e.target.value)
    }

    const changePahar22Oz = e => {
        setPahar22Oz(e.target.value)
    }

    const changePaharFrappeM = e => {
        setPaharFrappeM(e.target.value)
    }

    const changePaharFrappeL = e => {
        setPaharFrappeL(e.target.value)
    }

    const changeCapacFrappeML = e => {
        setCapacFrappeML(e.target.value)
    }

    const changeLogo = e => {
        setLogo(e.target.value)
    }

    const changeMacha = e => {
        setMacha(e.target.value)
    }

    const changeSweetMacha = e => {
        setSweetMacha(e.target.value)
    }

    const changeChai = e => {
        setChai(e.target.value)
    }

    const changeChicory = e => {
        setChicory(e.target.value)
    }

    const changeturmeric = e => {
        setTurmeric(e.target.value)
    }

    const changeCeai = e => {
        setCeai(e.target.value)
    }

    const changeCiocoLapte = e => {
        setCiocoLapte(e.target.value)
    }

    const changeCiocoNeagra = e => {
        setCiocoNeagra(e.target.value)
    }

    const changeCiocoAlba = e => {
        setLogo(e.target.value)
    }

    const changeZaharAlb = e => {
        setZaharAlb(e.target.value)
    }

    const changeZaharBrun = e => {
        setZaharBrun(e.target.value)
    }

    const changePaletina = e => {
        setPaletina(e.target.value)
    }

    const changeBomboana = e => {
        setBomboana(e.target.value)
    }

    const changeNecuantificabile = e => {
        setNecuantificabile(e.target.value)
    }

    const changePaie = e => {
        setPaie(e.target.value)
    }

    const flavourS = [
        'Denumire produs:',
        '',
        'Flavour S',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 170ml - ' + (lapteValue * 170).toString() + ' lei',
        '3) Sirop 20ml - ' + (siropValue * 20).toString() + ' lei',
        '4) Pahar 8oz - ' + (pahar8ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 8oz - ' + (capac8ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 170) + (siropValue * 20) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
        // 'TOTAL CU TVA - ' +  ((cafeaValue * 8) + (lapteValue * 170) + (siropValue * 20) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (7*0.19)).toFixed(2).toString(),
    ];

    const flavourM = [
        'Denumire produs:',
        '',
        'Flavour M',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '3) Sirop 20ml - ' + (siropValue * 20).toString() + ' lei',
        '4) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 250) + (siropValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const flavourL = [
        'Denumire produs:',
        '',
        'Flavour L',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '3) Sirop 20ml - ' + (siropValue * 20).toString() + ' lei',
        '4) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 300) + (siropValue * 20) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const flavourXL = [
        'Denumire produs:',
        '',
        'Flavour XL',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 450ml - ' + (lapteValue * 450).toString() + ' lei',
        '3) Sirop 20ml - ' + (siropValue * 20).toString() + ' lei',
        '4) Pahar 22oz - ' + (pahar22ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 22oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (lapteValue * 450) + (siropValue * 20) + (pahar22ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const cappuccinoS = [
        'Denumire produs:',
        '',
        'Cappuccino S',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 130ml - ' + (lapteValue * 130).toString() + ' lei',
        '3) Pahar 8oz - ' + (pahar8ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 8oz - ' + (capac8ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 130) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const cappuccinoM = [
        'Denumire produs:',
        '',
        'Cappuccino M',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 220ml - ' + (lapteValue * 220).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 220) + (pahar12ozValue) + (logoValue) + (capac12to22ozValue) + (baghetaZaharAlbValue) + (baghetaZaharBrunValue) + (paletinaValue) + (bomboanaValue) + (consumabileNecuantificabileValue)).toString(),
    ];

    const cappuccinoL = [
        'Denumire produs:',
        '',
        'Cappuccino L',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 250) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const cappuccinoXL = [
        'Denumire produs:',
        '',
        'Cappuccino XL',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 400ml - ' + (lapteValue * 400).toString() + ' lei',
        '3) Pahar 22oz - ' + (pahar22ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 22oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 400) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const latteS = [
        'Denumire produs:',
        '',
        'Latte S',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 170ml - ' + (lapteValue * 170).toString() + ' lei',
        '3) Pahar 8oz - ' + (pahar8ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 8oz - ' + (capac8ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 170) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const latteM = [
        'Denumire produs:',
        '',
        'Latte M',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const latteL = [
        'Denumire produs:',
        '',
        'Latte L',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const latteXL = [
        'Denumire produs:',
        '',
        'Latte XL',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 450ml - ' + (lapteValue * 450).toString() + ' lei',
        '3) Pahar 22oz - ' + (pahar22ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 22oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (lapteValue * 450) + (pahar22ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const mochacinoM = [
        'Denumire produs:',
        '',
        'Mochacino M',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '3) Ciocolata Lapte 20g - ' + (ciocolataLapteValue * 20).toString() + ' lei',
        '4) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 250) + (ciocolataLapteValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const mochacinoL = [
        'Denumire produs:',
        '',
        'Mochacino L',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '3) Ciocolata Lapte 40g - ' + (ciocolataLapteValue * 40).toString() + ' lei',
        '4) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 300) + (ciocolataLapteValue * 40) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const darkMochacinoM = [
        'Denumire produs:',
        '',
        'Dark Mochacino M',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '3) Ciocolata Neagra 20g - ' + (ciocolataNeagraValue * 20).toString() + ' lei',
        '4) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 250) + (ciocolataNeagraValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const darkMochacinoL = [
        'Denumire produs:',
        '',
        'Dark Mochacino L',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '3) Ciocolata Neagra 40g - ' + (ciocolataNeagraValue * 40).toString() + ' lei',
        '4) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 300) + (ciocolataNeagraValue * 40) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const whiteMochacinoM = [
        'Denumire produs:',
        '',
        'White Mochacino M',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '3) Ciocolata Alba 20g - ' + (ciocolataAlbaValue * 20).toString() + ' lei',
        '4) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 250) + (ciocolataAlbaValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const whiteMochacinoL = [
        'Denumire produs:',
        '',
        'White Mochacino L',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '3) Ciocolata Alba 40g - ' + (ciocolataAlbaValue * 40).toString() + ' lei',
        '4) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 300) + (ciocolataAlbaValue * 40) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed().toString(),
    ];

    const flatS = [
        'Denumire produs:',
        '',
        'Flat White S',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 130ml - ' + (lapteValue * 130).toString() + ' lei',
        '3) Pahar 8oz - ' + (pahar8ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 8oz - ' + (capac8ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 130) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const flatM = [
        'Denumire produs:',
        '',
        'Flat White M',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 240ml - ' + (lapteValue * 240).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (lapteValue * 240) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const espresso = [
        'Denumire produs:',
        '',
        'Espresso',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Pahar 4oz - ' + (pahar4ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 4oz - ' + (capac4ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (pahar4ozValue * 1) + (logoValue * 1) + (capac4ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const dubluEspresso = [
        'Denumire produs:',
        '',
        'Dublu Espresso',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Pahar 7oz - ' + (pahar7ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 7oz - ' + (capac7ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (pahar7ozValue * 1) + (logoValue * 1) + (capac7ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const cortado = [
        'Denumire produs:',
        '',
        'Cortado',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Lapte 30ml - ' + (lapteValue * 30).toString() + ' lei',
        '3) Pahar 7oz - ' + (pahar7ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 7oz - ' + (capac7ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (lapteValue * 30) + (pahar7ozValue * 1) + (logoValue * 1) + (capac7ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const americanoS = [
        'Denumire produs:',
        '',
        'Americano',
        '',
        'Reteta:',
        '',
        '1) Cafea 8g - ' + (cafeaValue * 8).toString() + ' lei',
        '2) Pahar 8oz - ' + (pahar8ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 8oz - ' + (capac8ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 8) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const americanoM = [
        'Denumire produs:',
        '',
        'Americano M',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16)  + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const americanoL = [
        'Denumire produs:',
        '',
        'Americano L',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24)  + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const americanoXL = [
        'Denumire produs:',
        '',
        'Americano XL',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Pahar 22oz - ' + (pahar22ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 22oz - ' + (capac12to22ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24)  + (pahar22ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue)* 1).toFixed(2).toString(),
    ];

    const buzzShot = [
        'Denumire produs:',
        '',
        'Buzz Shot',
        '',
        'Reteta:',
        '',
        '1) Cafea 32g - ' + (cafeaValue * 32).toString() + ' lei',
        '2) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 32)  + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const ciocolataCaldaM = [
        'Denumire produs:',
        '',
        'Ciocolata Calda M',
        '',
        'Reteta:',
        '',
        '1) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '2) Ciocolata Lapte 40g - ' + (ciocolataLapteValue * 40).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((lapteValue * 250) + (ciocolataLapteValue * 40) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const ciocolataCaldaL = [
        'Denumire produs:',
        '',
        'Ciocolata Calda L',
        '',
        'Reteta:',
        '',
        '1) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '2) Ciocolata Lapte 60g - ' + (ciocolataLapteValue * 60).toFixed(2).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((lapteValue * 300) + (ciocolataLapteValue * 60) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const chaiLatteM = [
        'Denumire produs:',
        '',
        'Chai Latte M',
        '',
        'Reteta:',
        '',
        '1) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '2) Chai Pudra 20g - ' + (chaiValue * 20).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((chaiValue * 20) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const chaiLatteL = [
        'Denumire produs:',
        '',
        'Chai Latte L',
        '',
        'Reteta:',
        '',
        '1) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '2) Chai Pudra 30g - ' + (chaiValue * 30).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((chaiValue * 8) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const machaM = [
        'Denumire produs:',
        '',
        'Macha M',
        '',
        'Reteta:',
        '',
        '1) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '2) Macha Pudra 3g - ' + (machaValue * 3).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((machaValue * 3) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const machaL = [
        'Denumire produs:',
        '',
        'Macha L',
        '',
        'Reteta:',
        '',
        '1) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '2) Macha Pudra 6g - ' + (machaValue * 6).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((machaValue * 6) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];


    const sweetMachaM = [
        'Denumire produs:',
        '',
        'Sweet Macha M',
        '',
        'Reteta:',
        '',
        '1) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '2) Sweet Macha Pudra 10g - ' + (sweetMachaValue * 10).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((sweetMachaValue * 10) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const sweetMachaL = [
        'Denumire produs:',
        '',
        'Macha L',
        '',
        'Reteta:',
        '',
        '1) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '2) Sweet Macha Pudra 20g - ' + (sweetMachaValue * 20).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((sweetMachaValue * 20) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue) * 1).toFixed(2).toString(),
    ];

    const chicoryM = [
        'Denumire produs:',
        '',
        'Chicory M',
        '',
        'Reteta:',
        '',
        '1) Lapte 250ml - ' + (lapteValue * 250).toFixed(2).toString() + ' lei',
        '2) Chicory Pudra 10g - ' + (chicoryValue * 10).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((chicoryValue * 10) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const chicoryL = [
        'Denumire produs:',
        '',
        'Chicory L',
        '',
        'Reteta:',
        '',
        '1) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '2) Chicory Pudra 15g - ' + (chicoryValue * 15).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((chicoryValue * 15) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const ceaiM = [
        'Denumire produs:',
        '',
        'Ceai M',
        '',
        'Reteta:',
        '',
        '1) 1 Plic - ' + (ceaiValue).toString() + ' lei',
        '2) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((ceaiValue * 1) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const ceaiL = [
        'Denumire produs:',
        '',
        'Ceai L',
        '',
        'Reteta:',
        '',
        '1) 2 Plicuri - ' + (ceaiValue * 2).toString() + ' lei',
        '2) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '3) Logo - ' + (logoValue).toString() + ' lei',
        '4) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '5) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '6) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '7) Paletina - ' + (paletinaValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((ceaiValue * 2)  + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const fogTeaM = [
        'Denumire produs:',
        '',
        'Fog Tea M',
        '',
        'Reteta:',
        '',
        '1) 1 Plic - ' + (ceaiValue).toString() + ' lei',
        '2) Sirop 20ml - ' + (siropValue * 20).toString() + ' lei',
        '3) Lapte 50ml - ' + (lapteValue * 50).toString() + ' lei',
        '4) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((ceaiValue * 1) + (lapteValue * 50) + (siropValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const fogTeaL = [
        'Denumire produs:',
        '',
        'Fog Tea L',
        '',
        'Reteta:',
        '',
        '1) 2 Plicuri - ' + (ceaiValue * 2).toString() + ' lei',
        '2) Sirop 20ml - ' + (siropValue * 20).toString() + ' lei',
        '3) Lapte 100ml - ' + (lapteValue * 100).toString() + ' lei',
        '4) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '7) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '8) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '9) Paletina - ' + (paletinaValue).toString() + ' lei',
        '10) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '11) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((ceaiValue * 2) + (lapteValue * 100) + (siropValue * 20) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const turmericLatteM = [
        'Denumire produs:',
        '',
        'Turmeric Latte M',
        '',
        'Reteta:',
        '',
        '1) Turmeric Pudra 5g - ' + (turmericValue * 5).toString() + ' lei',
        '2) Lapte 300ml - ' + (lapteValue * 300).toString() + ' lei',
        '3) Pahar 12oz - ' + (pahar12ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 12oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((turmericValue * 5) + (lapteValue * 300) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const turmericLatteL = [
        'Denumire produs:',
        '',
        'Turmeric Latte L',
        '',
        'Reteta:',
        '',
        '1) Turmeric Pudra 10g - ' + (turmericValue * 10).toString() + ' lei',
        '2) Lapte 350ml - ' + (lapteValue * 350).toString() + ' lei',
        '3) Pahar 16oz - ' + (pahar16ozValue).toString() + ' lei',
        '4) Logo - ' + (logoValue).toString() + ' lei',
        '5) Capac 16oz - ' + (capac12to22ozValue).toString() + ' lei',
        '6) Zahar alb - ' + (baghetaZaharAlbValue).toString() + ' lei',
        '7) Zahar brun - ' + (baghetaZaharBrunValue).toString() + ' lei',
        '8) Paletina - ' + (paletinaValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((turmericValue * 10) + (lapteValue * 350) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2).toString(),
    ];

    const espressoTonic = [
        'Denumire produs:',
        '',
        'Espresso Tonic',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Apa Tonica 200ml - ' + (apaTonicaValue * 200).toString() + ' lei',
        '3) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '4) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '5) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (apaTonicaValue * 200) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCaramelGelatoM = [
        'Denumire produs:',
        '',
        'Ice Caramel Gelato M',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 200ml - ' + (lapteValue * 200).toString() + ' lei',
        '3) Inghetata 30g - ' + (inghetataValue * 30).toString() + ' lei',
        '4) Topping 20g - ' + (toppingValue * 20).toString() + ' lei',
        '5) Frisca 30g - ' + (friscaValue * 30).toString() + ' lei',
        '6) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '7) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '8) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '9) Logo - ' + (logoValue).toString() + ' lei',
        '10) Pai - ' + (paieValue).toString() + ' lei',
        '11) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '12) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 200) + (inghetataValue * 30) + (toppingValue * 20) + (friscaValue * 30) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCaramelGelatoL = [
        'Denumire produs:',
        '',
        'Ice Caramel Gelato L',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 170ml - ' + (lapteValue * 170).toString() + ' lei',
        '3) Inghetata 30g - ' + (inghetataValue * 30).toString() + ' lei',
        '4) Topping 20g - ' + (toppingValue * 20).toString() + ' lei',
        '5) Frisca 30g - ' + (friscaValue * 30).toString() + ' lei',
        '6) Gheata 120gr - ' + (gheataValue * 120).toString() + ' lei',
        '7) Pahar Plastic L - ' + (paharFrapeLValue).toString() + ' lei',
        '8) Capac Plastic L - ' + (capacFrapeMLValue).toString() + ' lei',
        '9) Logo - ' + (logoValue).toString() + ' lei',
        '10) Pai - ' + (paieValue).toString() + ' lei',
        '11) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '12) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (lapteValue * 170) + (inghetataValue * 30) + (toppingValue * 20) + (friscaValue * 30) + (gheataValue * 120) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCafeLatteM = [
        'Denumire produs:',
        '',
        'Ice Cafe Latte M',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 200ml - ' + (lapteValue * 200).toString() + ' lei',
        '3) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '4) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '5) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 200) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceMachaLatteM = [
        'Denumire produs:',
        '',
        'Ice Macha Latte M',
        '',
        'Reteta:',
        '',
        '1) Macha 3g - ' + (machaValue * 3).toString() + ' lei',
        '2) Lapte 150ml - ' + (lapteValue * 150).toString() + ' lei',
        '3) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '4) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '5) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((machaValue * 3) + (lapteValue * 150) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceChaiLatteM = [
        'Denumire produs:',
        '',
        'Ice Chai Latte M',
        '',
        'Reteta:',
        '',
        '1) Chai 20g - ' + (chaiValue * 20).toString() + ' lei',
        '2) Lapte 150ml - ' + (lapteValue * 150).toString() + ' lei',
        '3) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '4) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '5) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((chaiValue * 20) + (lapteValue * 150) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCafeLatteL = [
        'Denumire produs:',
        '',
        'Ice Cafe Latte L',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 240ml - ' + (lapteValue * 240).toString() + ' lei',
        '3) Gheata 120gr - ' + (gheataValue * 120).toString() + ' lei',
        '4) Pahar Plastic L - ' + (paharFrapeLValue).toString() + ' lei',
        '5) Capac Plastic L - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (lapteValue * 240) + (gheataValue * 120) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceMachaLatteL = [
        'Denumire produs:',
        '',
        'Ice Macha Latte L',
        '',
        'Reteta:',
        '',
        '1) Macha 6g - ' + (machaValue * 6).toString() + ' lei',
        '2) Lapte 200ml - ' + (lapteValue * 200).toString() + ' lei',
        '3) Gheata 120gr - ' + (gheataValue * 120).toString() + ' lei',
        '4) Pahar Plastic L - ' + (paharFrapeLValue).toString() + ' lei',
        '5) Capac Plastic L - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((machaValue * 6) + (lapteValue * 200) + (gheataValue * 120) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceChaiLatteL = [
        'Denumire produs:',
        '',
        'Ice Chai Latte L',
        '',
        'Reteta:',
        '',
        '1) Chai 30g - ' + (chaiValue * 30).toString() + ' lei',
        '2) Lapte 200ml - ' + (lapteValue * 200).toString() + ' lei',
        '3) Gheata 120gr - ' + (gheataValue * 120).toString() + ' lei',
        '4) Pahar Plastic L - ' + (paharFrapeLValue).toString() + ' lei',
        '5) Capac Plastic L - ' + (capacFrapeMLValue).toString() + ' lei',
        '6) Logo - ' + (logoValue).toString() + ' lei',
        '7) Pai - ' + (paieValue).toString() + ' lei',
        '8) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '9) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((chaiValue * 30) + (lapteValue * 200) + (gheataValue * 120) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCafeLatteFlavouredM = [
        'Denumire produs:',
        '',
        'Ice Cafe Latte Flavoured M',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Lapte 180ml - ' + (lapteValue * 180).toString() + ' lei',
        '3) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '4) Sirop 20gr - ' + (siropValue * 20).toString() + ' lei',
        '5) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '6) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '7) Logo - ' + (logoValue).toString() + ' lei',
        '8) Pai - ' + (paieValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (lapteValue * 180) + (siropValue * 20) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCafeLatteFlavouredL = [
        'Denumire produs:',
        '',
        'Ice Cafe Latte Flavoured L',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Lapte 220ml - ' + (lapteValue * 220).toString() + ' lei',
        '3) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '4) Sirop 20gr - ' + (siropValue * 20).toString() + ' lei',
        '5) Pahar Plastic L - ' + (paharFrapeLValue).toString() + ' lei',
        '6) Capac Plastic L - ' + (capacFrapeMLValue).toString() + ' lei',
        '7) Logo - ' + (logoValue).toString() + ' lei',
        '8) Pai - ' + (paieValue).toString() + ' lei',
        '9) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '10) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (lapteValue * 220) + (siropValue * 20) + (gheataValue * 100) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCoffeeM = [
        'Denumire produs:',
        '',
        'Ice Coffee M',
        '',
        'Reteta:',
        '',
        '1) Cafea 16g - ' + (cafeaValue * 16).toString() + ' lei',
        '2) Gheata 100gr - ' + (gheataValue * 100).toString() + ' lei',
        '3) Pahar Plastic M - ' + (paharFrapeMValue).toString() + ' lei',
        '4) Capac Plastic M - ' + (capacFrapeMLValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Pai - ' + (paieValue).toString() + ' lei',
        '7) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '8) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 16) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) +  (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    const iceCoffeeL = [
        'Denumire produs:',
        '',
        'Ice Coffee L',
        '',
        'Reteta:',
        '',
        '1) Cafea 24g - ' + (cafeaValue * 24).toFixed(2).toString() + ' lei',
        '2) Gheata 120gr - ' + (gheataValue * 120).toString() + ' lei',
        '3) Pahar Plastic L - ' + (paharFrapeMValue).toString() + ' lei',
        '4) Capac Plastic L - ' + (capacFrapeMLValue).toString() + ' lei',
        '5) Logo - ' + (logoValue).toString() + ' lei',
        '6) Pai - ' + (paieValue).toString() + ' lei',
        '7) Bomboana - ' + (bomboanaValue).toString() + ' lei',
        '8) Necuantificabile - ' + (consumabileNecuantificabileValue).toString() + ' lei',
        '',
        'TOTAL PE PRODUS - ' +  ((cafeaValue * 24) + (gheataValue * 120) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) +  (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2).toString(),
    ];

    // const productMap = new Map();
    // productMap.set('flavourS', flavourS.join('\n'));
    // productMap.set('iceCaramelGelatoM', iceCaramelGelatoM.join('\n'));
    // productMap.set('cappuccinoS', cappuccinoS.join('\n'));
    const productMap = new Map();


    for (const item of options) {
        const rule = eval(item);

        if (Array.isArray(rule)) {
            productMap.set(item, rule.join('\n'));
        } else {
            console.log(`Array variable '${item}' is not defined.`);
        }
    }


    const totalFlavourS =  ((cafeaValue * 8) + (lapteValue * 170) + (siropValue * 20) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFlavourM = ((cafeaValue * 8) + (lapteValue * 250) + (siropValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFlavourL = ((cafeaValue * 16) + (lapteValue * 300) + (siropValue * 20) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFlavourXL = ((cafeaValue * 24) + (lapteValue * 450) + (siropValue * 20) + (pahar22ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCappuccinoS = ((cafeaValue * 8) + (lapteValue * 130) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCappuccinoM = ((cafeaValue * 8) + (lapteValue * 220) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCappuccinoL = ((cafeaValue * 16) + (lapteValue * 250) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCappuccinoXL = ((cafeaValue * 8) + (lapteValue * 400) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalLatteS = ((cafeaValue * 8) + (lapteValue * 170) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalLatteM = ((cafeaValue * 8) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalLatteL = ((cafeaValue * 16) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalLatteXL =  ((cafeaValue * 24) + (lapteValue * 450) + (pahar22ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalMochacinoM = ((cafeaValue * 8) + (lapteValue * 250) + (ciocolataLapteValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalMochacinoL = ((cafeaValue * 16) + (lapteValue * 300) + (ciocolataLapteValue * 40) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalDarkMochacinoM = ((cafeaValue * 8) + (lapteValue * 250) + (ciocolataNeagraValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalDarkMochacinoL = ((cafeaValue * 16) + (lapteValue * 300) + (ciocolataNeagraValue * 40) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalWhiteMochacinoM = ((cafeaValue * 8) + (lapteValue * 250) + (ciocolataAlbaValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalWhiteMochacinoL = ((cafeaValue * 16) + (lapteValue * 300) + (ciocolataAlbaValue * 40) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFlatS = ((cafeaValue * 16) + (lapteValue * 130) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFlatM = ((cafeaValue * 24) + (lapteValue * 240) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalEspresso = ((cafeaValue * 8) + (pahar4ozValue * 1) + (logoValue * 1) + (capac4ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalDubluEspresso = ((cafeaValue * 16) + (pahar7ozValue * 1) + (logoValue * 1) + (capac7ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCortado = ((cafeaValue * 8) + (lapteValue * 30) + (pahar7ozValue * 1) + (logoValue * 1) + (capac7ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalAmericanoS = ((cafeaValue * 8) + (pahar8ozValue * 1) + (logoValue * 1) + (capac8ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalAmericanoM = ((cafeaValue * 16)  + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalAmericanoL = ((cafeaValue * 24)  + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalAmericanoXL = ((cafeaValue * 24)  + (pahar22ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalBuzzShot = ((cafeaValue * 32)  + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCiocolataCaldaM = ((lapteValue * 250) + (ciocolataLapteValue * 40) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCiocolataCaldaL = ((lapteValue * 300) + (ciocolataLapteValue * 60) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalChaiLatteM = ((chaiValue * 20) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalChaiLatteL = ((chaiValue * 8) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalMachaM = ((machaValue * 3) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalMachaL = ((machaValue * 6) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalSweetMachaM = ((sweetMachaValue * 10) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalSweetMachaL = ((sweetMachaValue * 20) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalChicoryM = ((chicoryValue * 10) + (lapteValue * 250) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalChicoryL = ((chicoryValue * 15) + (lapteValue * 300) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCeaiM = ((ceaiValue * 1) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalCeaiL = ((ceaiValue * 2)  + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFogTeaM = ((ceaiValue * 1) + (lapteValue * 50) + (siropValue * 20) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalFogTeaL = ((ceaiValue * 2) + (lapteValue * 100) + (siropValue * 20) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalTurmericLatteM = ((turmericValue * 5) + (lapteValue * 300) + (pahar12ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalTurmericLatteL = ((turmericValue * 10) + (lapteValue * 350) + (pahar16ozValue * 1) + (logoValue * 1) + (capac12to22ozValue * 1) + (baghetaZaharAlbValue * 1) + (baghetaZaharBrunValue * 1) + (paletinaValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1)).toFixed(2)
    const totalEspressoTonic = ((cafeaValue * 16) + (apaTonicaValue * 200) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCaramelGelatoM = ((cafeaValue * 16) + (lapteValue * 200) + (inghetataValue * 30) + (toppingValue * 20) + (friscaValue * 30) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCaramelGelatoL = ((cafeaValue * 24) + (lapteValue * 170) + (inghetataValue * 30) + (toppingValue * 20) + (friscaValue * 30) + (gheataValue * 120) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCafeLatteM = ((cafeaValue * 16) + (lapteValue * 200) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCafeLatteL = ((cafeaValue * 24) + (lapteValue * 240) + (gheataValue * 120) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceMachaLatteM = ((machaValue * 3) + (lapteValue * 150) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceChaiLatteM = ((chaiValue * 20) + (lapteValue * 150) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceMachaLatteL = ((machaValue * 6) + (lapteValue * 200) + (gheataValue * 120) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1)  + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceChaiLatteL = ((chaiValue * 30) + (lapteValue * 200) + (gheataValue * 120) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCafeLatteFlavouredM = ((cafeaValue * 16) + (lapteValue * 180) + (siropValue * 20) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCafeLatteFlavouredL = ((cafeaValue * 24) + (lapteValue * 220) + (siropValue * 20) + (gheataValue * 100) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1)).toFixed(2)
    const totalIceCoffeeM = ((cafeaValue * 16) + (gheataValue * 100) + (paharFrapeMValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1))
    const totalIceCoffeeL = ((cafeaValue * 24) + (gheataValue * 120) + (paharFrapeLValue * 1) + (logoValue * 1) + (capacFrapeMLValue * 1) + + (bomboanaValue * 1) + (consumabileNecuantificabileValue * 1) + (paieValue * 1))

    const productCost = new Map();
    productCost.set('flavourS', totalFlavourS)
    productCost.set('flavourM', totalFlavourM)
    productCost.set('flavourL', totalFlavourL)
    productCost.set('flavourXL', totalFlavourXL)
    productCost.set('cappuccinoS', totalCappuccinoS)
    productCost.set('cappuccinoM', totalCappuccinoM)
    productCost.set('cappuccinoL', totalCappuccinoL)
    productCost.set('cappuccinoXL', totalCappuccinoXL)
    productCost.set('latteS', totalLatteS)
    productCost.set('latteM', totalLatteM)
    productCost.set('latteL', totalLatteL)
    productCost.set('latteXL', totalLatteXL)
    productCost.set('mochacinoM', totalMochacinoM)
    productCost.set('mochacinoL', totalMochacinoL)
    productCost.set('darkMochacinoM', totalDarkMochacinoM)
    productCost.set('darkMochacinoL', totalDarkMochacinoL)
    productCost.set('whiteMochacinoM', totalWhiteMochacinoM)
    productCost.set('whiteMochacinoL', totalWhiteMochacinoL)
    productCost.set('flatS', totalFlatS)
    productCost.set('flatM', totalFlatM)
    productCost.set('espresso', totalEspresso)
    productCost.set('dubluEspresso', totalDubluEspresso)
    productCost.set('cortado', totalCortado)
    productCost.set('americanoS', totalAmericanoS)
    productCost.set('americanoM', totalAmericanoM)
    productCost.set('americanoL', totalAmericanoL)
    productCost.set('americanoXL', totalAmericanoXL)
    productCost.set('buzzShot', totalBuzzShot)
    productCost.set('ciocolataCaldaM', totalCiocolataCaldaM)
    productCost.set('ciocolataCaldaL', totalCiocolataCaldaL)
    productCost.set('chaiLatteM', totalChaiLatteM)
    productCost.set('chaiLatteL', totalChaiLatteL)
    productCost.set('machaM', totalMachaM)
    productCost.set('machaL', totalMachaL)
    productCost.set('sweetMachaM', totalSweetMachaM)
    productCost.set('sweetMachaL', totalSweetMachaL)
    productCost.set('chicoryM', totalChicoryM)
    productCost.set('chicoryL', totalChicoryL)
    productCost.set('ceaiM', totalCeaiM)
    productCost.set('ceaiL', totalCeaiL)
    productCost.set('fogTeaM', totalFogTeaM)
    productCost.set('fogTeaL', totalFogTeaL)
    productCost.set('turmericLatteM', totalTurmericLatteM)
    productCost.set('turmericLatteL', totalTurmericLatteL)
    productCost.set('espressoTonic', totalEspressoTonic)
    productCost.set('iceCaramelGelatoM', totalIceCaramelGelatoM)
    productCost.set('iceCaramelGelatoL', totalIceCaramelGelatoL)
    productCost.set('iceCafeLatteM', totalIceCafeLatteM)
    productCost.set('iceCafeLatteL', totalIceCafeLatteL)
    productCost.set('iceMachaLatteM', totalIceMachaLatteM)
    productCost.set('iceMachaLatteL', totalIceMachaLatteL)
    productCost.set('iceChaiLatteM', totalIceChaiLatteM)
    productCost.set('iceChaiLatteL', totalIceChaiLatteL)
    productCost.set('iceCafeLatteFlavouredM', totalIceCafeLatteFlavouredM)
    productCost.set('iceCafeLatteFlavouredL', totalIceCafeLatteFlavouredL)
    productCost.set('iceCoffeeM', totalIceCoffeeM)
    productCost.set('iceCoffeeL', totalIceCoffeeL)





    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        // Handle option selection logic
    };

    const [inputs, setInputs] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [numberOfItems, setNumberOfItems] = useState(1);

    const handleAddInput = () => {
        if (inputs.includes(selectedItem)) {
            return; // Exit early if the item already exists
        }

        setInputs([...inputs, selectedItem]);
        setNumberOfItems((prevItems) => ({
            ...prevItems,
            [selectedItem]: prevItems[selectedItem] || 1
        }));
    };

    const handleInputChange = (index, value) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
    };

    const handleDropdownChange = (e) => {
        setSelectedItem(e.target.value);
    };

    const handleNumberOfItemsChange = (e, input) => {
        const { value } = e.target;
        setNumberOfItems((prevItems) => ({
            ...prevItems,
            [input]: value
        }));
    };

    const handleDeleteAll = () => {
        setInputs([]);
    };

    // Calculate sum //

    const handleCalculateSum = () => {
        const sum = calculateTotalSum();
        // Do something with the calculated sum, such as displaying an alert
        alert(`The total sum is: ${sum}`);
    };

    const calculateTotalSum = () => {
        let sum = 0;
        inputs.forEach((input) => {
            const itemValue = productCost.get(input.toString()) * numberOfItems[input];
            const itemQuantity = parseInt(numberOfItems[input], 10) || 0; // Use 0 as default if quantity is null or undefined
            if (!isNaN(itemQuantity)) {
                sum += itemValue;
            }
        });
        return sum.toFixed(2);
    };

    const calculateTotalSum1 = () => {
        let sum = 0;
        inputs.forEach((input) => {
            const itemValue = productRealCost.get(input.toString()) * numberOfItems[input];
            if (!isNaN(itemValue)) {
                sum += itemValue;
            }
        });
        return sum.toFixed(2);
    };

    const calculateTotalSum2 = () => {
        let sum = 0;
        inputs.forEach((input) => {
            const itemValue = productRealCost.get(input.toString()) * numberOfItems[input] - productCost.get(input.toString()) * numberOfItems[input];
            if (!isNaN(itemValue)) {
                sum += itemValue;
            }
        });
        return sum.toFixed(2);
    };

    const allOptions = [
        'flavourS', 'flavourM', 'flavourL', 'flavourXL',
        'cappuccinoS', 'cappuccinoM', 'cappuccinoL', 'cappuccinoXL',
        'latteS', 'latteM', 'latteL', 'latteXL',
        'mochacinoM', 'mochacinoL',
        'darkMochacinoM', 'darkMochacinoL',
        'whiteMochacinoM', 'whiteMochacinoL',
        'flatS', 'flatM',
        'espresso', 'dubluEspresso',
        'cortado',
        'americanoS', 'americanoM', 'americanoL', 'americanoXL',
        'buzzShot',
        'ciocolataCaldaM', 'ciocolataCaldaL',
        'chaiLatteM', 'chaiLatteL',
        'machaM', 'machaL',
        'sweetMachaM', 'sweetMachaL',
        'chicoryM', 'chicoryL',
        'ceaiM', 'ceaiL',
        'fogTeaM', 'fogTeaL',
        'turmericLatteM', 'turmericLatteL',
        'espressoTonic',
        'iceCaramelGelatoM', 'iceCaramelGelatoL',
        'iceCafeLatteM', 'iceCafeLatteL',
        'iceMachaLatteM', 'iceMachaLatteL',
        'iceChaiLatteM', 'iceChaiLatteL',
        'iceCafeLatteFlavouredM', 'iceCafeLatteFlavouredL',
        'iceCoffeeM', 'iceCoffeeL'
    ];



    const handleAddAllItems = () => {
        setInputs(allOptions);
        const initialNumberOfItems = {};
        allOptions.forEach(option => {
            initialNumberOfItems[option] = 1;
        });
        setNumberOfItems(initialNumberOfItems);
    };

    const handleRemoveItem = (itemToRemove) => {
        setInputs(inputs.filter(item => item !== itemToRemove));
        // Also remove the corresponding quantity from the numberOfItems state
        setNumberOfItems(prevState => {
            const updatedState = { ...prevState };
            delete updatedState[itemToRemove];
            return updatedState;
        });
    };


    return (
        <div>
            <Navbar />
            <div className="title-retetar">
                <h1>Retetar</h1>
                <br/>
                <br/>
                <div>
                    <p>Alege data pentru preturi</p>
                    <br/>
                    {/* Render the dropdown */}
                    <select value={selectedOptionData} onChange={handleSelectChangeDataAndOra} className={"data-preturi"}>
                        <option value="">Select an option</option>
                        {preturi && preturi.map((recipe, index) => (
                            <option key={index} value={`Data: ${recipe.data} / Ora: ${recipe.ora}`}>
                                {`Data: ${recipe.data} / Ora: ${recipe.ora}`}
                            </option>
                        ))}
                    </select>
                    <br/>
                    <br/>
                    {/* Display the selected value */}
                    {selectedOptionData && <p>Selected value: {selectedOptionData}</p>}
                </div>
                <br/>
                <br/>
                <div>
                    Alege produsul:
                    <DropdownButton
                        options={options}
                        selectedOption={selectedOption}
                        onSelect={handleOptionSelect}
                    />
                </div>
                <div className="item-name">{productMap.get(selectedOption.toString())}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<p>PRET DE LISTA - {productRealCost.get(selectedOption.toString())}</p>*/}
                    <br/>
                    <br/>
                    <p>TVA - {productTVA.get(selectedOption.toString())}</p>
                    {/*<br/>*/}
                    {/*<p>PROFIT - {(productRealCost.get(selectedOption.toString()) - productCost.get(selectedOption.toString()) - productTVA.get(selectedOption.toString())).toFixed(2)}</p>*/}
                    <Plot
                        data={[
                            {
                                values: [
                                    parseFloat((productRealCost.get(selectedOption.toString()) - productCost.get(selectedOption.toString()) - productTVA.get(selectedOption.toString()))),
                                    parseFloat(productCost.get(selectedOption.toString())),
                                    parseFloat(productTVA.get(selectedOption.toString()))
                                ],
                                labels: ['Profit', 'Cost Productie', 'TVA'],
                                type: 'pie',
                                // text: labels1.map((label, index) => `${label}: ${cashAndCard[1]} `),
                                // text: [(productRealCost.get(selectedOption.toString()) - productCost.get(selectedOption.toString())).toFixed(2), productCost.get(selectedOption.toString())],
                                text: [(productRealCost.get(selectedOption.toString()) - productCost.get(selectedOption.toString()) - productTVA.get(selectedOption.toString())).toFixed(2), productCost.get(selectedOption.toString()), productTVA.get(selectedOption.toString())],
                                textposition: 'inside',
                                hoverinfo: 'text',
                                marker: {
                                    // colors: ['green', 'blue'],
                                    colors: ['green', 'blue', 'orange'], // Set custom colors for the pie slices
                                },
                            },
                        ]}
                        layout={{
                            width: 600,
                            height: 450,
                            title:
                                'Pret de Lista: ' +
                                productRealCost.get(selectedOption.toString()) +
                                '\n' +
                                'Cost Productie: ' +
                                productCost.get(selectedOption.toString()) +
                                '\n' +
                                'Profit: ' +
                                (productRealCost.get(selectedOption.toString()) - productCost.get(selectedOption.toString()) - productTVA.get(selectedOption.toString())).toFixed(2) +
                                '\n',
                        }}
                    />
                </div>
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h1 style={{margin: "15px"}}>Aduaga noi preturi la meteria prima</h1>
                    <br/>
                    <br/>
                    <form action="https://roasted.ro/insert_retete.php" method="post" className="form-preturi">
                        <p className="no-wrap-data" style={{marginTop: "20px"}}>
                            <label className="label-conta-batistei-data" style={{marginTop: "40px"}}>
                                Data
                            </label>
                            <input onChange={changeData} className="margin-label-conta" type="date" name="data" id="data" defaultValue={currentDate}
                                   value={data} style={{padding: "2px 20px", color: "black"}} />
                        </p>
                        <p className="no-wrap-data">
                            <label className="label-conta-batistei-data" style={{marginTop: "40px"}}>
                                Ora (hh:mm:ss)
                            </label>
                            <input onChange={changeOra} className="margin-label-retetar" type="text" name="ora" id="ora" defaultValue={currentTime}
                                   value={ora} style={{padding: "2px 20px", color: "black"}} />
                        </p>
                        <div className="conta-line-data"></div>
                        <p className="no-wrap">
                            <label className="label-retetar-cafea" >
                                Cafea
                            </label>
                            <input onChange={changeCafea} className="margin-label-retetar" type="text" name="cafea" defaultValue={cafeaValue}
                                   value={cafea}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-lapte">
                                Lapte
                            </label>
                            <input onChange={changeLapte} className="margin-label-retetar" type="int" name="lapte" defaultValue={lapteValue}
                                   value={lapte}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-sirop">
                                Sirop
                            </label>
                            <input onChange={changeSirop} className="margin-label-retetar" type="int" name="sirop" defaultValue={siropValue}
                                   value={sirop}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-inghetata">
                                Inghetata
                            </label>
                            <input onChange={changeInghetata} className="margin-label-retetar" type="int" name="inghetata" defaultValue={inghetataValue}
                                   value={inghetata}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-gheata">
                                Gheata
                            </label>
                            <input onChange={changeGheata} className="margin-label-retetar" type="int" name="gheata" defaultValue={gheataValue}
                                   value={gheata}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-frisca">
                                Frisca
                            </label>
                            <input onChange={changeFrisca} className="margin-label-retetar" type="int" name="frisca" defaultValue={friscaValue}
                                   value={frisca}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-topping">
                                Topping
                            </label>
                            <input onChange={changeTopping} className="margin-label-retetar" type="int" name="topping" defaultValue={toppingValue}
                                   value={topping}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-apaTonica">
                                Apa Tonica
                            </label>
                            <input onChange={changeApaTonica} className="margin-label-retetar" type="int" name="apaTonica" defaultValue={apaTonicaValue}
                                   value={apaTonica}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-pahar4Oz">
                                Pahar 4oz
                            </label>
                            <input onChange={changePahar4Oz} className="margin-label-retetar" type="int" name="pahar4Oz" defaultValue={pahar4ozValue}
                                   value={pahar4Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-capac4Oz">
                                Capac 4oz
                            </label>
                            <input onChange={changeCapac4Oz} className="margin-label-retetar" type="int" name="capac4Oz" defaultValue={capac4ozValue}
                                   value={capac4Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-pahar7Oz">
                                Pahar 7oz
                            </label>
                            <input onChange={changePahar7Oz} className="margin-label-retetar" type="int" name="pahar7Oz" defaultValue={pahar7ozValue}
                                   value={pahar7Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-capac7Oz">
                                Capac 7oz
                            </label>
                            <input onChange={changeCapac7Oz} className="margin-label-retetar" type="int" name="capac7Oz" defaultValue={capac7ozValue}
                                   value={capac7Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-pahar8Oz">
                                Pahar 8oz
                            </label>
                            <input onChange={changePahar8Oz} className="margin-label-retetar" type="int" name="pahar8Oz" defaultValue={pahar8ozValue}
                                   value={pahar8Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-capac8Oz">
                                Capac 8oz
                            </label>
                            <input onChange={changeCapac8Oz} className="margin-label-retetar" type="int" name="capac8Oz" defaultValue={capac8ozValue}
                                   value={capac8Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-pahar12Oz">
                                Pahar 12oz
                            </label>
                            <input onChange={changePahar12Oz} className="margin-label-retetar" type="int" name="pahar12Oz" defaultValue={pahar12ozValue}
                                   value={pahar12Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-capac12Oz">
                                Cap 12-22 oz
                            </label>
                            <input onChange={changeCapac12Oz} className="margin-label-retetar" type="int" name="capac12Oz" defaultValue={capac12to22ozValue}
                                   value={capac12Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-pahar16Oz">
                                Pahar 16oz
                            </label>
                            <input onChange={changePahar16Oz} className="margin-label-retetar" type="int" name="pahar16Oz" defaultValue={pahar16ozValue}
                                   value={pahar16Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-pahar22Oz">
                                Pahar 22oz
                            </label>
                            <input onChange={changePahar22Oz} className="margin-label-retetar" type="int" name="pahar22Oz" defaultValue={pahar22ozValue}
                                   value={pahar22Oz}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-frappeM">
                                Pahar Frap M
                            </label>
                            <input onChange={changePaharFrappeM} className="margin-label-retetar" type="int" name="paharFrappeM" defaultValue={paharFrapeMValue}
                                   value={paharFrappeM}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-frappeL">
                                Pahar Frap L
                            </label>
                            <input onChange={changePaharFrappeL} className="margin-label-retetar" type="int" name="paharFrappeL" defaultValue={paharFrapeLValue}
                                   value={paharFrappeL}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-capacFrappeML">
                                Cap Frap M-L
                            </label>
                            <input onChange={changeCapacFrappeML} className="margin-label-retetar" type="int" name="capacFrappeML"  defaultValue={capacFrapeMLValue}
                                   value={capacFrappeML}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-logo">
                                Logo
                            </label>
                            <input onChange={changeLogo} className="margin-label-retetar" type="int" name="logo" defaultValue={logoValue}
                                   value={logo}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-macha">
                                Macha
                            </label>
                            <input onChange={changeMacha} className="margin-label-retetar" type="int" name="macha" defaultValue={machaValue}
                                   value={macha}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-sweetMacha">
                                Sweet Macha
                            </label>
                            <input onChange={changeSweetMacha} className="margin-label-retetar" type="int" name="sweetMacha" defaultValue={sweetMachaValue}
                                   value={sweetMacha}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-chai">
                                Chai
                            </label>
                            <input onChange={changeChai} className="margin-label-retetar" type="int" name="chai" defaultValue={chaiValue}
                                   value={chai}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-chicory">
                                Chicory
                            </label>
                            <input onChange={changeChicory} className="margin-label-retetar" type="int" name="chicory" defaultValue={chicoryValue}
                                   value={chicory}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-turmeric">
                                Turmeric
                            </label>
                            <input onChange={changeturmeric} className="margin-label-retetar" type="int" name="turmeric" defaultValue={turmericValue}
                                   value={turmeric}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-ceai">
                                Ceai
                            </label>
                            <input onChange={changeCeai} className="margin-label-retetar" type="int" name="ceai" defaultValue={ceaiValue}
                                   value={ceai}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-ciocoLapte">
                                Cioco Lapte
                            </label>
                            <input onChange={changeCiocoLapte} className="margin-label-retetar" type="int" name="ciocoLapte" defaultValue={ciocolataLapteValue}
                                   value={ciocoLapte}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-ciocoNeagra">
                                Cioco Neagra
                            </label>
                            <input onChange={changeCiocoNeagra} className="margin-label-retetar" type="int" name="ciocoNeagra" defaultValue={ciocolataAlbaValue}
                                   value={ciocoNeagra}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-ciocoAlba">
                                Cioco Alba
                            </label>
                            <input onChange={changeCiocoAlba} className="margin-label-retetar" type="int" name="ciocoAlba" defaultValue={ciocolataNeagraValue}
                                   value={ciocoAlba}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-zaharAlb">
                                Zahar Alb
                            </label>
                            <input onChange={changeZaharAlb} className="margin-label-retetar" type="int" name="zaharAlb" defaultValue={baghetaZaharAlbValue}
                                   value={zaharAlb}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-zaharBrun">
                                Zahar Brun
                            </label>
                            <input onChange={changeZaharBrun} className="margin-label-retetar" type="int" name="zaharBrun" defaultValue={baghetaZaharBrunValue}
                                   value={zaharBrun}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-paletina">
                                Paletina
                            </label>
                            <input onChange={changePaletina} className="margin-label-retetar" type="int" name="paletina" defaultValue={paletinaValue}
                                   value={paletina}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-bomboana">
                                Bomboana
                            </label>
                            <input onChange={changeBomboana} className="margin-label-retetar" type="int" name="bomboana" defaultValue={bomboanaValue}
                                   value={bomboana}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-necuantificabile">
                                Necuantif
                            </label>
                            <input onChange={changeNecuantificabile} className="margin-label-retetar" type="int" name="necuantificabile" defaultValue={consumabileNecuantificabileValue}
                                   value={necuantificabile}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-retetar-paie">
                                Paie
                            </label>
                            <input onChange={changePaie} className="margin-label-retetar" type="int" name="paie" defaultValue={paieValue}
                                   value={paie}/>
                        </p>
                        <br/>
                        <p style={{marginRight: "8px"}}>
                            <button type="submit" className="margin-label-submit-conta" style={{marginBottom: "20px"}}>Modifica</button>
                            {/*<input  className="margin-label-submit-conta" type="submit" value={submitText} />*/}
                        </p>
                    </form>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Calculator:</h1>
                    <br/>
                    <br/>
                    <select className="drop-retetar" value={selectedItem} onChange={handleDropdownChange}>
                        <option value="">Alege Produsul</option>
                        <option value="flavourS">Flavour S</option>
                        <option value="flavourM">Flavour M</option>
                        <option value="flavourL">Flavour L</option>
                        <option value="flavourXL">Flavour XL</option>
                        <option value="cappuccinoS">Cappuccino S</option>
                        <option value="cappuccinoM">Cappuccino M</option>
                        <option value="cappuccinoL">Cappuccino L</option>
                        <option value="cappuccinoXL">Cappuccino XL</option>
                        <option value="latteS">Latte S</option>
                        <option value="latteM">Latte M</option>
                        <option value="latteL">Latte L</option>
                        <option value="latteXL">Latte XL</option>
                        <option value="mochacinoM">Mochacino M</option>
                        <option value="mochacinoL">Mochacino L</option>
                        <option value="darkMochacinoM">Dark Mochacino M</option>
                        <option value="darkMochacinoL">Dark Mochacino L</option>
                        <option value="whiteMochacinoM">White Mochacino M</option>
                        <option value="whiteMochacinoL">White Mochacino L</option>
                        <option value="flatS">Flat White S</option>
                        <option value="flatM">Flat White M</option>
                        <option value="espresso">Espresso</option>
                        <option value="dubluEspresso">Dublu Espresso</option>
                        <option value="cortado">Cortado</option>
                        <option value="americanoS">Americano S</option>
                        <option value="americanoM">Americano M</option>
                        <option value="americanoL">Americano L</option>
                        <option value="americanoXL">Americano XL</option>
                        <option value="buzzShot">Buzz Shot</option>
                        <option value="ciocolataCaldaM">Ciocolata Calda M</option>
                        <option value="ciocolataCaldaL">Ciocolata Calda L</option>
                        <option value="chaiLatteM">Chai Latte M</option>
                        <option value="chaiLatteL">Chai Latte L</option>
                        <option value="machaM">Macha M</option>
                        <option value="machaL">Macha L</option>
                        <option value="sweetMachaM">Sweet Macha M</option>
                        <option value="sweetMachaL">Sweet Macha L</option>
                        <option value="chicoryM">Chicory M</option>
                        <option value="chicoryL">Chicory L</option>
                        <option value="ceaiM">Ceai M</option>
                        <option value="ceaiL">Ceai L</option>
                        <option value="fogTeaM">Fog Tea M</option>
                        <option value="fogTeaL">Fog Tea L</option>
                        <option value="turmericLatteM">Turmeric Latte M</option>
                        <option value="turmericLatteL">Turmeric Latte L</option>
                        <option value="espressoTonic">Espresso Tonic</option>
                        <option value="iceCaramelGelatoM">Ice Caramel Gelato M</option>
                        <option value="iceCaramelGelatoL">Ice Caramel Gelato L</option>
                        <option value="iceCafeLatteM">Ice Cafe Latte M</option>
                        <option value="iceCafeLatteL">Ice Cafe Latte L</option>
                        <option value="iceMachaLatteM">Ice Macha Latte M</option>
                        <option value="iceMachaLatteL">Ice Macha Latte L</option>
                        <option value="iceChaiLatteM">Ice Chai Latte M</option>
                        <option value="iceChaiLatteL">Ice Chai Latte L</option>
                        <option value="iceCafeLatteFlavouredM">Ice Cafe Latte Flavoured M</option>
                        <option value="iceCafeLatteFlavouredL">Ice Cafe Latte Flavoured L</option>
                        <option value="iceCoffeeM">Ice Coffee M</option>
                        <option value="iceCoffeeL">Ice Coffee L</option>
                    </select>
                    <button onClick={handleAddInput} className="btn-retetar" disabled={!selectedItem}>
                        Adauga Produs
                    </button>
                    <button onClick={handleAddAllItems} className="btn-retetar">
                        Adauga toate produsele
                    </button>
                    <button onClick={handleDeleteAll} className="btn-retetar" disabled={inputs.length === 0}>
                        Sterge Tot
                    </button>
                    <br/>
                    <br/>
                    <br/>
                    <h2>Lista de produse</h2>
                    <br/>
                    <br/>
                    {inputs.map((input, index) => (
                        <div key={index}>
                            <input
                                className="drop-retetar"
                                value={input}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                readOnly
                            />
                            <input
                                className="drop-retetar-number"
                                type="number"
                                min="1"
                                value={numberOfItems[input]}
                                onChange={(e) => handleNumberOfItemsChange(e, input)}
                            />
                            <button className="btn-retetar" onClick={() => handleRemoveItem(input)}>
                                Sterge
                            </button>
                        </div>
                    ))}
                    <div className="item-summary">
                        {inputs.map((input, index) => (
                            <div key={index}>
                                <div className="item-name">{input}</div>
                                <div className="item-total">
                                    <div className="item-total1">Pret de Lista: {((productRealCost.get(input.toString())) * numberOfItems[input]).toFixed(2)} </div>
                                    <div className="item-total1">Cost de Productie: {(productCost.get(input.toString()) * numberOfItems[input]).toFixed(2)} </div>
                                    <div className="item-total1">Profit pe Produs: {((productRealCost.get(input.toString()) - productCost.get(input.toString())).toFixed(2) * numberOfItems[input]).toFixed(2)} </div>
                                    <div className="item-value">Cantitate: {numberOfItems[input]}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="total-sum">
                        <br/>
                        <h3>Cifra de afaceri:</h3>
                        <div>{calculateTotalSum1()}</div>
                        <br/>
                        <h3>Cost total:</h3>
                        <div>{calculateTotalSum()}</div>
                        <br/>
                        <h3>Profit:</h3>
                        <div>{calculateTotalSum2()}</div>
                        <br/>
                    </div>
                    <Plot
                        data={[
                            {
                                values: [
                                    calculateTotalSum2(),
                                    calculateTotalSum(),
                                ],
                                labels: ['Profit', 'Cost Productie'],
                                type: 'pie',
                                // text: labels1.map((label, index) => `${label}: ${cashAndCard[1]} `),
                                text: [calculateTotalSum2(), calculateTotalSum()],
                                textposition: 'inside',
                                hoverinfo: 'text',
                                marker: {
                                    colors: ['green', 'blue'], // Set custom colors for the pie slices
                                },
                            },
                        ]}
                        layout={{
                            width: 600,
                            height: 450,
                            title:
                                'Cifra de Afaceri: ' +
                                calculateTotalSum1() +
                                '\n' +
                                'Cost Productie: ' +
                                calculateTotalSum() +
                                '\n' +
                                'Profit: ' +
                                calculateTotalSum2() +
                                '\n',
                        }}
                    />
                    <br/>
                    {/*<button onClick={handleCalculateSum}>Calculeaza Suma</button>*/}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    );
};
