import "./nutritionale.css"
import Navbar from "../menu/menu";
import DropdownList from "../components/DropdownList";


export default function Nutritionale() {
    return (
        <div>
            <Navbar/>
            <h1 className="title-nutr">Valori Nutritionale</h1>
            <div className="center-menu">
                <DropdownList/>
            </div>
        </div>
    )
}