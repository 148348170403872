// data.js

const nutritionale = [
    {
        "name": "Lapte 3.5",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 266,
            "energy_kcal": 63.5,
            "total_fat": 3.5,
            "saturated_fat": 2,
            "carbohydrates": 4.5,
            "sugars": 4.5,
            "protein": 3.3,
            "sodium": 0.1
        }
    },
    {
        "name": "Lapte fara lactoza",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 278,
            "energy_kcal": 67,
            "total_fat": 3.8,
            "saturated_fat": 2.5,
            "carbohydrates": 4.8,
            "sugars": 4.8,
            "protein": 3.3,
            "sodium": 0.13
        }
    },
    {
        "name": "Bautura ovaz",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 154,
            "energy_kcal": 37,
            "total_fat": 1.2,
            "saturated_fat": 0.1,
            "carbohydrates": 5.6,
            "sugars": 4.4,
            "protein": 0.4,
            "sodium": 0.1
        }
    },
    {
        "name": "Cafea",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 33.6,
            "energy_kcal": 8,
            "total_fat": 0,
            "saturated_fat": 0,
            "carbohydrates": 5.04,
            "sugars": 0,
            "protein": 5.04,
            "sodium": 0.168
        }
    },
    {
        "name": "Sirop",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 1333,
            "energy_kcal": 314,
            "total_fat": 0.5,
            "saturated_fat": 0.1,
            "carbohydrates": 78,
            "sugars": 78,
            "protein": 0.5,
            "sodium": 0.02
        }
    },
    {
        "name": "Topping caramel",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 1338,
            "energy_kcal": 316,
            "total_fat": 4.1,
            "saturated_fat": 2.6,
            "carbohydrates": 67,
            "sugars": 59,
            "protein": 2.8,
            "sodium": 0.22
        }
    },
    {
        "name": "Topping ciocolata",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 1364,
            "energy_kcal": 322,
            "total_fat": 3.2,
            "saturated_fat": 1.9,
            "carbohydrates": 69,
            "sugars": 54,
            "protein": 2.4,
            "sodium": 0.16
        }
    },
    {
        "name": "Frisca",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 1293,
            "energy_kcal": 313,
            "total_fat": 28,
            "saturated_fat": 19,
            "carbohydrates": 12,
            "sugars": 12,
            "protein": 2.5,
            "sodium": 0.16
        }
    },
    {
        "name": "Inghetata",
        "quantity": "100ml / 1 ml",
        "nutritional_info": {
            "energy_kj": 928,
            "energy_kcal": 222,
            "total_fat": 12.5,
            "saturated_fat": 8.6,
            "carbohydrates": 24.2,
            "sugars": 21.7,
            "protein": 3.1,
            "sodium": 0.07
        }
    },
    {
        "name": "Ciocolata neagra",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 545,
            "energy_kcal": 130,
            "total_fat": 34.3,
            "saturated_fat": 20.7,
            "carbohydrates": 48.4,
            "sugars": 43.2,
            "protein": 6.2,
            "sodium": 0.006
        }
    },
    {
        "name": "Ciocolata alba",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 560,
            "energy_kcal": 133,
            "total_fat": 33.5,
            "saturated_fat": 20.2,
            "carbohydrates": 60.4,
            "sugars": 59.7,
            "protein": 4.3,
            "sodium": 0.26
        }
    },
    {
        "name": "Ciocolata lapte",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 554,
            "energy_kcal": 132.40,
            "total_fat": 33.6,
            "saturated_fat": 20.3,
            "carbohydrates": 55.9,
            "sugars": 53.8,
            "protein": 5.8,
            "sodium": 0.25
        }
    },
    {
        "name": "Chai pudra",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 1615,
            "energy_kcal": 380,
            "total_fat": 0.4,
            "saturated_fat": 0.2,
            "carbohydrates": 92.2,
            "sugars": 70.1,
            "protein": 1.6,
            "sodium": 0.65
        }
    },
    {
        "name": "Matcha pudra organica",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 1528,
            "energy_kcal": 362,
            "total_fat": 6.5,
            "saturated_fat": 0.8,
            "carbohydrates": 53,
            "sugars": 2,
            "protein": 37,
            "sodium": 0.05
        }
    },
    {
        "name": "Matcha pudra indulcita",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 1654,
            "energy_kcal": 390,
            "total_fat": 0.4,
            "saturated_fat": 0.1,
            "carbohydrates": 91,
            "sugars": 85.5,
            "protein": 2.9,
            "sodium": 0
        }
    },
    {
        "name": "Cicoare pudra",
        "quantity": "100gr / 1gr",
        "nutritional_info": {
            "energy_kj": 1280,
            "energy_kcal": 305,
            "total_fat": 0.1,
            "saturated_fat": 0.1,
            "carbohydrates": 56,
            "sugars": 13,
            "protein": 3.8,
            "sodium": 0.36
        }
    },
    {
        "name": "Ceai",
        "quantity": "100gr",
        "nutritional_info": {
            "energy_kj": "kj",
            "energy_kcal": "kcal",
            "total_fat": "Grasimi",
            "saturated_fat": "Acizi grași",
            "carbohydrates": "Glucide",
            "sugars": "Zaharuri",
            "protein": "Proteine",
            "sodium": "Sare"
        }
    }
]



const data = [
    {
        id: 1,
        title: 'Espresso',
        category: 'Black',
        ingr: 'apa, cafea / 2 kcal',
        gr: 'gramaj: 25ml',
        ingredients: "apa: 25 ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 8 kcal / 33.6 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 5.04 g, zaharuri: 0 g, " +
            "proteine: 5.04 g, sare: 0.0168 g",
        alergens: "alergeni: -",

    },
    {
        id: 2,
        title: 'Doppio',
        category: 'Black',
        ingr: 'apa, cafea / 4 kcal',
        gr: 'gramaj: 50ml',
        ingredients: "apa: 50 ml, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 16 kcal / 67.2 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 10.08 g, zaharuri: 0 g, " +
            "proteine: 10.08 g, sare: 0.0336 g",
        alergens: "alergeni: -"
    },
    {
        id: 3,
        title: 'Americano',
        category: 'Black',
        ingr: 'apa, cafea / 2 kcal',
        gr: 'gramaj: 25ml',
        ingredients: "apa: 100 ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 8 kcal / 33.6 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 5.04 g, zaharuri: 0 g, " +
            "proteine: 5.04 g, sare: 0.0168 g",
        alergens: "alergeni: -",

    },
    {
        id: 4,
        title: 'Buzz Shot',
        category: 'Black',
        ingr: 'apa, cafea / 8 kcal',
        gr: 'gramaj: 100ml',
        ingredients: "apa: 100 ml, cafea Blend Roasted: 32 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 32 kcal / 134.4 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 20.16 g, zaharuri: 0 g, " +
            "proteine: 20.16 g, sare: 0.0672 g",
        alergens: "alergeni: -"
    },
    {
        id: 5,
        title: 'Extra Shot',
        category: 'Black',
        ingr: 'apa, cafea / 2 kcal',
        gr: 'gramaj: 25ml',
        ingredients: "apa: 25 ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 8 kcal / 33.6 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 5.04 g, zaharuri: 0 g, " +
            "proteine: 5.04 g, sare: 0.0168 g",
        alergens: "alergeni: -",
    },
    {
        id: 6,
        title: 'Cortado',
        category: 'White',
        ingr: 'lapte, cafea / 21.05 kcal',
        gr: 'gramaj: 55ml',
        ingredients: "lapte 3.5 %: 30 ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%.)\n",
        "additionalInfo": "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 67.1 kcal / 280.74 kJ, " +
            "grasimi: 1.925 g, " +
            "acizi grasi saturati: 2 g, " +
            "glucide: 4.743 g, zaharuri: 2.475 g, " +
            "proteine: 4.743 g, sare: 0.13 g",
        alergens: "alergeni: lapte",
    },
    {
        "id": 7,
        "title": "Cafe Latte",
        "category": "White",
        "ingr": "lapte, cafea / 109.95 kcal",
        "gr": "gramaj: 195ml",
        "ingredients": "lapte 3.5 %: 170 ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%.)\n",
        "additionalInfo": "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 71.5 kcal / 299.6 kJ, " +
            "grasimi: 3.5 g, " +
            "acizi grasi saturati: 2 g, " +
            "glucide: 9.54 g, zaharuri: 4.5 g, " +
            "proteine: 8.34 g, sare: 0.268 g",
        "alergens": "alergeni: lapte"
    },
    {
        id: 8,
        title: 'Cappuccino',
        category: 'White',
        ingr: 'lapte, cafea / 84.55 kcal',
        gr: 'gramaj: 155ml',
        ingredients: "lapte 3.5 %: 130 ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%.)\n",
        "additionalInfo": "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 71.5 kcal / 299.6 kJ, " +
            "grasimi: 3.5 g, " +
            "acizi grasi saturati: 2 g, " +
            "glucide: 9.54 g, zaharuri: 4.5 g, " +
            "proteine: 8.34 g, sare: 0.268 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 9,
        title: 'Flat White',
        category: 'White',
        ingr: 'lapte, cafea / 86.55 kcal',
        gr: 'gramaj: 180ml',
        "additionalInfo": "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 100 kcal / 416.2 kJ, " +
            "grasimi: 3.5 g, " +
            "acizi grasi saturati: 2 g, " +
            "glucide: 14.58 g, zaharuri: 4.5 g, " +
            "proteine: 13.38 g, sare: 0.436 g",
        "alergens": "alergeni: lapte"
    },
    {
        id: 10,
        title: 'Flavoured',
        category: 'White',
        "ingr": "lapte, cafea, sirop / 172.75 kcal",
        "gr": "gramaj: 195ml",
        "ingredients": "lapte 3.5 %: 170 ml, sirop 20ml, cafea Blend Roasted: 8 g  (continut cafeina minim 1.5%), sirop: 25 ml\n",
        "additionalInfo": "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 387.15 kcal / 1617.6 kJ, " +
            "grasimi: 4 g, " +
            "acizi grasi saturati: 2.1 g, " +
            "glucide: 87.54 g, zaharuri: 82.5 g, " +
            "proteine: 8.84 g, sare: 0.288 g",
        "alergens": "alergeni: lapte"
    },
    {
        id: 11,
        title: 'Oat Milk',
        category: 'White',
        ingr: '',
        gr: 'gramaj: 100ml',
        ingredients: "",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 37 kcal / 154 kJ, " +
            "grasimi: 1.2 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 5.6 g, zaharuri: 4.4 g, " +
            "proteine: 0.4 g, sare: 0.1 g",
        alergens: "alergeni: -"
    },
    {
        id: 12,
        title: 'Extra Sirop',
        category: 'White',
        ingr: 'sirop / 62.8 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "",
        "additionalInfo": "" +
            "\n" +
            "energy: 314 kcal / 1333 kJ, " +
            "total_fat: 0.5 g, " +
            "saturated_fat: 0.1 g, " +
            "carbohydrates: 78 g, sugars: 78 g, " +
            "protein: 0.5 g, sodium: 0.02 g",
        "alergens": "alergeni: -",
    },
    {
        id: 13,
        title: 'Extra Frisca',
        category: 'White',
        ingr: '',
        gr: '',
        ingredients: "",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 313 kcal / 1293 kJ, " +
            "grasimi: 28 g, " +
            "acizi grasi saturati: 19 g, " +
            "glucide: 12 g, zaharuri: 12 g, " +
            "proteine: 2.5 g, sare: 0.16 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 14,
        title: 'Chai Tea Latte',
        category: 'Not Coffee',
        ingr: 'lapte, chai / 200 kcal',
        gr: 'gramaj: 270ml',
        ingredients: "lapte 3.5 %: 250ml , chai pudra 20g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g" +
            "valoare energetica: 86.96 kcal / 366.05 kJ, " +
            "grasimi: 3.27 g, " +
            "acizi grasi saturati: 1.865 g," +
            "glucide: 10.99 g, zaharuri: 9.35 g," +
            "proteine: 3.17 g, sare: 0.1408 g",
            alergens: "alergeni: lapte",
    },
    {
        id: 15,
        title: 'Turmeric Latte',
        category: 'Not Coffee',
        ingr: 'lapte, turmeric / 206.32 kcal',
        gr: 'gramaj: 305ml',
        ingredients: "lapte 3.5 %: 300ml, turmeric: 5g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 69.61 kcal / 291.24 kJ, " +
            "grasimi: 3.498 g, " +
            "acizi grasi saturati: 1.99 g, " +
            "glucide: 5.832 g, zaharuri: 4.49 g, " +
            "proteine: 3.33 g, sare: 0.997 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 16,
        title: 'Hot Milk Chocolate',
        category: 'Not Coffee',
        ingr: 'lapte, ciocolata cu lapte / 211.71 kcal',
        gr: 'gramaj: 290ml',
        ingredients: "lapte 3.5 %: 250ml, ciocoalta cu lapte 40g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 73.01 kcal / 305.47 kJ, " +
            "grasimi: 7.653 g, " +
            "acizi grasi saturati: 4.524 g, " +
            "glucide: 11.593 g, zaharuri: 11.303 g, " +
            "proteine: 3.644 g, sare: 0.1206 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 17,
        title: 'Hot Dark Chocolate',
        category: 'Not Coffee',
        ingr: 'lapte, ciocolata neagra / 210.75 kcal',
        gr: 'gramaj: 290ml',
        ingredients: "lapte 3.5 %: 250ml, ciocoalta cu neagra 40g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g" +
            "valoare energetica: 74.14 kcal / 310.20 kJ, " +
            "grasimi: 8.428 g, " +
            "acizi grasi saturati: 4.992 g, " +
            "glucide: 11.524 g, zaharuri: 10.692 g, " +
            "proteine: 3.764 g, sare: 0.08496 g. ",
        alergens: "alergeni: lapte",
    },
    {
        id: 18,
        title: 'Hot White Chocolate',
        category: 'Not Coffee',
        ingr: 'lapte, ciocolata alba / 210.75 kcal',
        gr: 'gramaj: 290ml',
        ingredients: "lapte 3.5 %: 250ml, ciocoalta cu neagra 40g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g" +
            "valoare energetica: 75.54 kcal / 316.06, " +
            "grasimi: 8.218 g, " +
            "acizi grasi saturati: 4.892 g, " +
            "glucide: 13.824 g, zaharuri: 11.992 g, " +
            "proteine: 3.264 g, sare: 0.14456 g. ",
        alergens: "alergeni: lapte",
    },
    {
        id: 19,
        title: 'Lactose Free Milk',
        category: 'White',
        ingr: '',
        gr: 'gramaj: 100ml',
        ingredients: "",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 67 kcal / 278 kJ, " +
            "grasimi: 3.8 g, " +
            "acizi grasi saturati: 2.5 g, " +
            "glucide: 4.8 g, zaharuri: 4.8 g, " +
            "proteine: 3.3 g, sare: 0.13 g",
        alergens: "alergeni: -"
    },
    {
        id: 20,
        title: 'Fog Tea Latte',
        category: 'Not Coffee',
        ingr: 'lapte, ceai, sirop / 94.55 kcal',
        gr: 'gramaj: 260ml' ,
        ingredients: "apa: 120 ml, lapte 3.5 %: 50ml, sirop: 20 ml, ceai: 1 plic\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 34.508 kcal / 144.382 kJ, " +
            "grasimi: 0.707 g, " +
            "acizi grasi saturati: 0.391 g, " +
            "glucide: 6.322 g, zaharuri: 6.322 g, " +
            "proteine: 0.668 g, sare: 0.0206 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 21,
        title: 'Chicory Latte',
        category: 'Not Coffee',
        ingr: 'lapte, cicoare / 189.53 kcal',
        gr: 'gramaj: 260ml',
        ingredients: "lapte 3.5 %: 250ml, cicoare pudra: 10g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 73.16 kcal / 306 kJ, " +
            "grasimi: 3.36 g, " +
            "acizi grasi saturati: 1.92 g, " +
            "glucide: 6.56 g, zaharuri: 4.84 g, " +
            "proteine: 3.32 g, sare: 0.1104 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 22,
        title: 'Hot Tea',
        category: 'Not Coffee',
        ingr: 'apa, ceai / 0 kcal',
        gr: 'gramaj: 260ml',
        ingredients: "apa: 260 ml, ceai: 1 plic\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 0 kcal / 0 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 0 g, zaharuri: 0 g, " +
            "proteine: 0 g, sare: 0 g",
        alergens: "alergeni: -",
    },
    {
        id: 23,
        title: 'Macha Organic',
        category: 'Not Coffee',
        ingr: 'lapte, macha organic / 169,61 kcal',
        gr: 'gramaj: 253ml',
        ingredients: "lapte 3.5 %: 250ml, macha organic: 3g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 67.1 kcal / 280.7 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 0 g, zaharuri: 0 g, " +
            "proteine: 0 g, sare: 0 g",
        alergens: "alergeni: -",
    },
    {
        id: 24,
        title: 'Macha Sweet',
        category: 'Not Coffee',
        ingr: 'apa, ceai / 2 kcal',
        gr: 'gramaj: 260ml',
        ingredients: "lapte 3.5 %: 250ml, macha sweetened: 10g\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 0 kcal / 0 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 0 g, zaharuri: 0 g, " +
            "proteine: 0 g, sare: 0 g",
        alergens: "alergeni: -",
    },
    {
        id: 25,
        title: 'Ice Cafe Latte',
        category: 'Froasted',
        ingr: 'lapte, gheata, cafea / 2 kcal',
        gr: 'gramaj: 350ml',
        ingredients: "lapte 3.5 %: 200ml, gheta: 100g, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 71.5 kcal / 299.6 kJ, " +
            "grasimi: 3.5 g, " +
            "acizi grasi saturati: 2 g, " +
            "glucide: 9.54 g, zaharuri: 4.5 g, " +
            "proteine: 8.34 g, sare: 0.268 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 26,
        title: 'Expresso Tonic',
        category: 'Froasted',
        ingr: 'apa tonica, gheata, cafea / 76.8 kcal',
        gr: 'gramaj: 350ml',
        ingredients: "apa tonica: 200ml, gheata: 100g, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 22.67 kcal / 94,83, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 5.78 g, zaharuri: 5.073 g, " +
            "proteine: 0.7 g, sare: 0.0227 g",
        alergens: "alergeni: -",
    },
    {
        id: 27,
        title: 'Ice Falvoured Cafe Latte',
        category: 'Froasted',
        ingr: 'lapte, gheata, sirop, cafea / 225.55 kcal',
        gr: 'gramaj: 400ml',
        ingredients: "lapte 3.5 %: 250ml, gheta: 100g, sirop: 20g, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 56.38 kcal / 235.89 kJ, " +
            "grasimi: 2.212 g, " +
            "acizi grasi saturati: 1.9 g, " +
            "glucide: 7.342 g, zaharuri: 6.712 g, " +
            "proteine: 2.717 g, sare: 0.0845 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 28,
        title: 'Ice Coffee No Milk',
        category: 'Froasted',
        ingr: 'gheata, cafea / 16 kcal',
        gr: 'gramaj: 150ml',
        ingredients: "gheata: 100g, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 2.64 kcal / 11.088 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 1.66 g, zaharuri: 0 g, " +
            "proteine: 1.66 g, sare: 0.00554 g",
        alergens: "alergeni: -",
    },
    {
        id: 29,
        title: 'Gelato Cafe Caramel',
        category: 'Froasted',
        ingr: 'lapte, inghetata, gheata, topping, frisca, cafea / 386.45 kcal',
        gr: 'gramaj: 430ml',
        ingredients: "lapte 3.5 %: 250ml, gheta: 100g, inghetata: 30g, frisca: 30g, topping caramel: 20g, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 89.868 kcal / 376 kJ, " +
            "grasimi: 5.152 g, " +
            "acizi grasi saturati: 3.212 g, " +
            "glucide: 8.823 g, zaharuri: 7.694 g, " +
            "proteine: 3.015 g, sare: 0.1037 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 30,
        title: 'Gelato Cafe Chocolate',
        category: 'Froasted',
        ingr: 'lapte, inghetata, gheata, topping, frisca, cafea / 2 kcal',
        gr: 'gramaj: 430ml',
        ingredients: "lapte 3.5 %: 250ml, gheta: 100g, inghetata: 30g, frisca: 30g, topping ciocolata: 20g, cafea Blend Roasted: 16 g  (continut cafeina minim 1.5%.)\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 90.012 kcal / 377.92 kJ, " +
            "grasimi: 4.901 g, " +
            "acizi grasi saturati: 3.115 g, " +
            "glucide: 8.923 g, zaharuri: 7.574 g, " +
            "proteine: 2.945 g, sare: 0.1067 g",
        alergens: "alergeni: lapte",
    },
    {
        id: 31,
        title: 'Banana Bread',
        category: 'Desert',
        ingr: 'banane, zahar brun, faina, lapte vegetal, ulei vegetal, seminte de in, nuci, bicarbonat de sodiu, sare, scortisoara / 194.72 kcal',
        gr: 'gramaj: 100g',
        ingredients: "\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 194.072 kcal / 814.70 kJ, " +
            "grasimi: 18.5 g, " +
            "acizi grasi saturati: 4.4 g, " +
            "glucide: 58.54 g, zaharuri: 32.57 g, " +
            "proteine: 4.22 g, sare: 1.13 mg",
        alergens: "alergeni: gluten, nuci",
    },
    {
        id: 32,
        title: 'Banana Bread Chocolate',
        category: 'Desert',
        ingr: 'banane, zahar brun, faina, lapte vegetal, ulei vegetal, seminte de in, nuci, bicarbonat de sodiu, sare, scortisoara, ciocolata neagra, cacao / 194.72 kcal',
        gr: 'gramaj: 100g',
        ingredients: "\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 199.95 kcal / 839.32 kJ, " +
            "grasimi: 20 g, " +
            "acizi grasi saturati: 5.3 g, " +
            "glucide: 61.02 g, zaharuri: 34.96 g, " +
            "proteine: 4.47 g, sare: 1.14 mg",
        alergens: "alergeni: gluten, nuci",
    },
    {
        id: 33,
        title: 'Mojito Mint',
        category: 'Flavours',
        ingr: 'sirop / 90 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, aroma naturala de menta",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 284 kcal / 1207 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 71 g, zaharuri: 71 g, " +
            "proteine: 0.5 g, sare: 0.1 g",
        alergens: "alergeni: -",
    },
    {
        id: 34,
        title: 'Green Mint',
        category: 'Flavours',
        ingr: 'sirop / 90 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, menta, caramel, FD&C Blue nr 1",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 288 kcal / 1124 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 72 g, zaharuri: 72 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 35,
        title: 'Tiramisu',
        category: 'Flavours',
        ingr: 'sirop / 90 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de\n" +
            "zahar, apa, arome, colorant: caramel" +
            " simplu.",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 314 kcal / 1335 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 79 g, zaharuri: 78 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 36,
        title: 'Amaretto',
        category: 'Flavours',
        ingr: 'sirop / 80 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, aroma, acidifiant: acid citric, colorant: E150a",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 328 kcal / 1396 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 82.1 g, zaharuri: 82 g, " +
            "proteine: 0.5 g, sare: 0.1 g",
        alergens: "alergeni: -",
    },
    {
        id: 37,
        title: 'Cinnamon',
        category: 'Flavours',
        ingr: 'sirop / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, caramel simplu, aroma naturala de scortioara",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 320 kcal / 1360 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 80 g, zaharuri: 80 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 38,
        title: 'Caribbean',
        category: 'Flavours',
        ingr: 'sirop / 90 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, acid citric, aroma de caramel simplu, aroma naturala",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 300 kcal / 1275 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 75 g, zaharuri: 75 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 39,
        title: 'Coconut',
        category: 'Flavours',
        ingr: 'sirop / 78 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "sirop de zahar, apa, arome,\n" +
            "acidifiant: acid citric\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 328 kcal / 1396 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 77.3 g, zaharuri: 77.3 g, " +
            "proteine: 0.5 g, sare: 0.03 g",
        alergens: "alergeni: -",
    },
    {
        id: 55,
        title: 'Creme Bruleee Cafe',
        category: 'Flavours',
        ingr: 'apa, cafea / 80 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar, apa, arome naturale, colorant: caramel E150b\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 315 kcal / 1338 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 78 g, zaharuri: 77 g, " +
            "proteine: 0 g, sare: 0 g",
        alergens: "alergeni: -",
    },
    {
        id: 40,
        title: 'Macadamia Nut',
        category: 'Flavours',
        ingr: 'sirop / 78 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de " +
            "zahar, apa, arome, colorant: E150a, infuzie de nuci de macadamia\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 321 kcal / 1341 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 78 g, zaharuri: 78 g, " +
            "proteine: 0.5 g, sare: 0.1 g",
        alergens: "alergeni: -",
    },
    {
        id: 56,
        title: 'Macarons',
        category: 'Flavours',
        ingr: 'sirop / 66 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar, aroma naturala, apa, acidifiant: acid citric",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 330 kcal / 1381 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 82.7 g, zaharuri: 82.3 g, " +
            "proteine: 0 g, sare: 0.03g",
        alergens: "alergeni: -",
    },
    {
        id: 42,
        title: 'Pistachio',
        category: 'Flavours',
        ingr: 'sirop / 75 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar\n" +
            "din" +
            "trestie de zahar, apa, aroma, acid" +
            "citric, aroma naturala de fistic cu alte" +
            "arome naturale\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100ml " +
            "valoare energetica: 312 kcal / 1326 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 78 g, zaharuri: 78 g, " +
            "proteine: 0.5 g, sare: 0.1 g",
        alergens: "alergeni: -",
    },
    {
        id: 43,
        title: 'Roasted Special',
        category: 'Flavours',
        ingr: 'sirop / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din\n" +
            "trestie de" +
            "zahar, apa, arome (alune), colorant: " +
            "caramel simplu.",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 320 kcal / 1362 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 80 g, zaharuri: 80 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 44,
        title: 'Hazelnut',
        category: 'Flavours',
        ingr: 'sirop / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar\n" +
            "din trestie de zahar, apa, aroma (alune), colorant: caramel simplu.\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 320 kcal / 1360 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 80 g, zaharuri: 80 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 45,
        title: 'Vanilla',
        category: 'Flavours',
        ingr: 'apa, cafea / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, aroma " +
            "caramel simplu, aroma " +
            "naturala de vanilie.\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 324 kcal / 1377 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 81 g, zaharuri: 81 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },
    {
        id: 46,
        title: 'Irish Cream',
        category: 'Flavours',
        ingr: 'sirop / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de " +
            "zahar, apa, arome, colorant: caramel simplu\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 318 kcal / 1350 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 79 g, zaharuri: 79 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },

    {
        id: 47,
        title: 'Pumpkin Spice',
        category: 'Flavours',
        ingr: 'sirop / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de " +
            "zahar, apa, arome naturale, aroma de scortisoara, colorant: caramel, aroma naturala de nucsoara, aroma naturala de cuisoare\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 320 kcal / 1360 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 80 g, zaharuri: 79 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },

    {
        id: 48,
        title: 'Toffee Crunch',
        category: 'Flavours',
        ingr: 'sirop / 75 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de " +
            "zahar, apa, arome, acid citric, colorant: E150a\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 314 kcal / 1333 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 78.4 g, zaharuri: 77 g, " +
            "proteine: 0.5 g, sare: 0.1 g",
        alergens: "alergeni: -",
    },

    {
        id: 49,
        title: 'Salted Caramel',
        category: 'Flavours',
        ingr: 'apa, cafea / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, sare, arome naturale, acid citric, caramel\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 328 kcal / 1394 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 82 g, zaharuri: 82 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },


    {
        id: 50,
        title: 'Caramel',
        category: 'Flavours',
        ingr: 'sirop/ 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din tresie de zahar, apa, aroma, acid citric, colorant: caramel natural\n",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 328 kcal / 1394 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 82 g, zaharuri: 82 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },

    {
        id: 51,
        title: 'Marshmallow',
        category: 'Flavours',
        ingr: 'sirop / 90 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, arome artificiale",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 311 kcal / 1323 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 78 g, zaharuri: 78 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },

    {
        id: 52,
        title: 'Chocolate Cookie',
        category: 'Flavours',
        ingr: 'sirop / 100 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "zahar din trestie de zahar, apa, aroma, colorant: caramel simplu",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 324 kcal / 1377 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 81 g, zaharuri: 80 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },

    {
        id: 53,
        title: 'Caramel No Sugar',
        category: 'Flavours',
        ingr: 'sirop / 0 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "apa, dextrina, aroma, acidifiant: acid citric, indulcitori: acesulfam K, sucraloza, colorant: caramel, agent de ingrosare,: guma de xanthan, conservanti: sorbat de potasiu",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 1 kcal / 4 kJ, " +
            "grasimi: 0 g, " +
            "acizi grasi saturati: 0 g, " +
            "glucide: 0 g, zaharuri: 0 g, " +
            "proteine: 0 g, sare: 0.03 g",
        alergens: "alergeni: -",
    },

    {
        id: 54,
        title: 'Vanilla No Sugar',
        category: 'Flavours',
        ingr: 'sirop / 0 kcal',
        gr: 'gramaj: 20ml',
        ingredients: "apa, dextrina, aroma, acidifiant: acid citric, indulcitori: acesulfam K, sucraloza, colorant: caramel, agent de ingrosare,: guma de xanthan, conservanti: sorbat de potasiu",
        additionalInfo: "" +
            "\n" +
            "valori nutritionale / 100g " +
            "valoare energetica: 324 kcal / 1377 kJ, " +
            "grasimi: 0.5 g, " +
            "acizi grasi saturati: 0.1 g, " +
            "glucide: 81 g, zaharuri: 80 g, " +
            "proteine: 0.5 g, sare: 0.02 g",
        alergens: "alergeni: -",
    },

    // Add more items with categories
];

export default data;



