import {useEffect, useState} from "react";
import React from 'react';
import {Link} from "react-router-dom"
import "./menu.css";
import menuButton from "../assets/centered-justification.png"
import logoMobile from "../assets/Asset 4.png"
import arrow from "../assets/img.png"

export default function Navbar() {

    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [isMenuActivated, setIsMenuActivated] = useState(false);
    const [isScrollLocked, setScrollLocked] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isInitialLoad == true)
                setOpacity(1)
            setScrollLocked(false);
            setIsInitialLoad(false);
        }, 3000);
    }, []);


    function moveToHome() {
        moveToTop();
    }


    function moveToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    function moveToContact() {
        closeMobileMenu();
        setTimeout(() => { window.scrollTo(0,2400); }, 1);
    }

    function moveToLocatii() {
        closeMobileMenu();
        setTimeout(() => { window.scrollTo(0,1100); }, 1);
    }

    function moveToMeniu() {
        moveToTop();
        closeMobileMenu();
    }



    function mobileAndMove(){
        moveToTop();
        closeMobileMenu();
    }

    const handleClick = () => setIsMenuActivated(!isMenuActivated);
    const closeMobileMenu = () => setIsMenuActivated(false);




    return (
        <div>
        <div className="nav-main"  >
            {/*style={{opacity}}*/}
            <div className="nav-menu">
                <div className="button-section">
                    <Link to="/">
                        <img className="menu-logo-mobile" src={logoMobile} alt="no-logo-for-mobile"/>
                    </Link>
                        <img className="menu-button" src={menuButton} alt="no-menu-button" onClick={handleClick}/>
                </div>
                <ul className={!isMenuActivated ? "inactive menu-options" : "menu-options"}>
                    <div className="menu-links">
                    <Link to="/franciza" className="link-nav" onClick={closeMobileMenu}>
                        <li className="menu-option">Franciza</li>
                    </Link>
                    <Link to="/locatii" className="link-nav" onClick={moveToLocatii}>
                        <li className="menu-option">Locatii</li>
                    </Link>
                    <Link to="/meniu" className="link-nav" onClick={moveToMeniu}>
                        <li className="menu-option">Meniu</li>
                    </Link>
                    <Link to="/microprajitorie" className="link-nav" onClick={closeMobileMenu}>
                        <li className="menu-option">Microprajitorie</li>
                    </Link>
                    <Link to="/contact" className="link-nav" onClick={moveToContact}>
                        <li className="menu-option">Contact</li>
                    </Link>
                    </div>
                </ul>
            </div>
        </div>
        <div className={isMenuActivated ? "inactive scroll-to-top" : "scroll-to-top"} onClick={moveToTop}>
            {/*style={{opacity}}*/}
            <img className="arrow" src={arrow} alt="no arrow"/>
        </div>
        </div>
    )
}
