import Plot from 'react-plotly.js'
import React, {useEffect} from "react";
import "./conta.css"
import "./plot.css"
import { PDFDocument, rgb } from 'pdf-lib';
import Item from "./Item";
import {useState} from "react";
import Navbar from "../menu/menu";
import DropdownButton from './dropdown';
import "./meniu.css"
import { API_KEY } from './config';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default function PlotChart(props)

// gestiune.cafenele@gmail.com

{


    const [cashAndCard, setCashAndCard] = useState([10,20]);
    const [chart, setChart] = useState(0)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [x1, setX1] = useState(0)
    const [y1, setY1] = useState(0)
    const [totalVanzari, setTotalVanzari] = useState(0)
    const [cafeaChart, setCafeaChart] = useState(10)
    const [lapteChart, setLapteChart] = useState(10)
    const [alternativChart, setAlternativChart] = useState(10)
    const [cashChart, setCashChart] = useState(10)
    const [bonuri, setBonuri] = useState(10)
    const [bonuri1, setBonuri1] = useState(10)
    const [bonuri2, setBonuri2] = useState(10)
    const [bonuriTotal, setBonuriTotal] = useState(10)
    const [cardChart, setCardChart] = useState(10)
    const [cafeaChart1, setCafeaChart1] = useState(10)
    const [lapteChart1, setLapteChart1] = useState(10)
    const [alternativChart1, setAlternativChart1] = useState(10)
    const [cashChart1, setCashChart1] = useState(10)
    const [cardChart1, setCardChart1] = useState(10)
    const [cafeaChart2, setCafeaChart2] = useState(10)
    const [lapteChart2, setLapteChart2] = useState(10)
    const [alternativChart2, setAlternativChart2] = useState(10)
    const [cashChart2, setCashChart2] = useState(10)
    const [cardChart2, setCardChart2] = useState(10)
    const [dataChart, setdataChart] = useState(0)
    const [cafeaChartTot, setCafeaChartTot] = useState(10)
    const [lapteChartTot, setLapteChartTot] = useState(10)
    const [alternativChartTot, setAlternativChartTot] = useState(10)
    const [cashChartTot, setCashChartTot] = useState(10)
    const [cardChartTot, setCardChartTot] = useState(10)
    const [dataChart1, setdataChart1] = useState(0)
    const [dataChart2, setdataChart2] = useState(0)
    const labels = ['Cafea', 'Lapte', 'Alternativ']
    const labels1 = ['Cash', 'Card']
    const labels2 = ['Incasari/Lapte', 'Incasari/Cafea - 4%']
    const options = ['Consumatie Batistei', 'Consumatie Italiana', 'Consumatie Lahovari', "Consumatie Tot"];
    const options1 = ['Incasari Batistei', 'Incasari Italiana', 'Incasari Lahovari', "Incasari Tot"];
    const options3 = ['Comenzi Batistei', 'Comenzi Italiana', 'Comenzi Lahovari', "Comenzi Tot"];
    const luni = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const luni_str = ['01', '02','03', '04','05', '06','07', '08','09', '10','11', '12']
    const zile = [31,28,31,30,31,30,31,31,30,31,30,31]
    const options2 = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const options4 = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [selectedOption, setSelectedOption] = React.useState(options[0]);
    const [selectedOption1, setSelectedOption1] = React.useState(options1[0]);
    const [selectedOption2, setSelectedOption2] = React.useState(options2[0]);
    const [selectedOption3, setSelectedOption3] = React.useState(options3[0]);
    const [selectedOption4, setSelectedOption4] = React.useState(options4[0]);
    const [sumMonth, setSumMonth] = useState(0)
    const [sumMonthCafea, setSumMonthCafea] = useState(0)
    const [sumMonthLapte, setSumMonthLapte] = useState(0)
    const [sumMonthCard, setSumMonthCard] = useState(0)
    const [sumMonthCash, setSumMonthCash] = useState(0)
    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handleOptionSelect1 = (option) => {
        setSelectedOption1(option);
    };

    const handleOptionSelect2 = (option) => {
        setSelectedOption2(option);
    };

    const handleOptionSelect3 = (option) => {
        setSelectedOption3(option);
    };

    const handleOptionSelect4 = (option) => {
        setSelectedOption4(option);
    };


    let trace3 = {
        x: ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'],
        y: [100, 110, 90, 60, 120, 150, 90],
        type: 'bar',
        name: 'Cash',
        // text: y.map(String),
        // textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
            color: 'rgb(46,180,46)',
            opacity: 1,
        }
    };

    let trace4 = {
        x: ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'],
        y: [100, 110, 90, 60, 120, 150, 90],
        type: 'bar',
        name: 'Card',
        // text: y.map(String),
        // textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
            color: 'rgb(15,63,222)',
            opacity: 1,
        }
    };

    let trace5 = {
        x: ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'],
        y: [100, 110, 90, 60, 120, 150, 90],
        type: 'bar',
        name: 'Card',
        // text: y.map(String),
        // textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
            color: 'rgb(32,168,32)',
            opacity: 1,
        }
    };

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [specificItem, setSpecificItem] = useState(null);
    const [inputValue1, setInputValue1] = useState('');
    const [specificItem1, setSpecificItem1] = useState(null);
    const [inputValue2, setInputValue2] = useState('');
    const [inputValue3, setInputValue3] = useState('');
    const [specificItem2, setSpecificItem2] = useState(null);

    useEffect(() => {
        fetch('https://roasted.ro/batistei.php',  {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })

            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    useEffect(() => {
        fetch('https://roasted.ro/italiana.php', {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setData1(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    useEffect(() => {
        fetch('https://roasted.ro/lahovari.php', {
            headers: {
                Authorization: 'cafelutza'
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setData2(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);


    useEffect(() => {
        fetch('https://roasted.ro/comenzi_cafenele_date.php', {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setData3(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);


    const handleDeleteOrder = async (orderId) => {
        try {
            // Make an API call to delete the order with the given orderId
            const response = await fetch(`https://roasted.ro/comenzi_cafenele_date.php?id=${orderId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: 'cafelutza'
                },
            });

            if (response.ok) {
                const updatedData = data3.filter(item => item.ID !== orderId);
                setData3(updatedData);
            } else {
                console.error('Error deleting order:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputChange1 = (event) => {
        setInputValue1(event.target.value);
    };

    const handleInputChange2 = (event) => {
        setInputValue2(event.target.value);
    };

    const handleInputChange3 = (event) => {
        setInputValue3(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (selectedOption == 'Consumatie Batistei') {
            const foundItem = data.find((item) => item.data === inputValue);
            setSpecificItem(foundItem);
            if (specificItem != null)
            {
                setCafeaChartTot(specificItem.cafea)
                setLapteChartTot(specificItem.lapte)
                setAlternativChartTot(specificItem.lapte_alternativ)
                setdataChart(specificItem.data)
                setCashChartTot(specificItem.cash)
                setCardChartTot(specificItem.card)
                setBonuriTotal(specificItem.bonuri)
            }
        }

        else if (selectedOption == 'Consumatie Italiana') {
            const foundItem1 = data1.find((item) => item.data === inputValue);
            setSpecificItem(foundItem1);
            if (specificItem != null)
            {
                setCafeaChartTot(specificItem.cafea)
                setLapteChartTot(specificItem.lapte)
                setAlternativChartTot(specificItem.lapte_alternativ)
                setdataChart(specificItem.data)
                setCashChartTot(specificItem.cash)
                setCardChartTot(specificItem.card)
                setBonuriTotal(specificItem.bonuri)
            }
        }

        else if (selectedOption == 'Consumatie Lahovari') {
            const foundItem2 = data2.find((item) => item.data === inputValue);
            setSpecificItem(foundItem2);
            if (specificItem != null)
            {
                setCafeaChartTot(specificItem.cafea)
                setLapteChartTot(specificItem.lapte)
                setAlternativChartTot(specificItem.lapte_alternativ)
                setdataChart(specificItem.data)
                setCashChartTot(specificItem.cash)
                setCardChartTot(specificItem.card)
                setBonuriTotal(specificItem.bonuri)
            }
        }

        else if (selectedOption == 'Consumatie Tot') {
            const foundItem = data.find((item) => item.data === inputValue);
            setSpecificItem(foundItem);
            const foundItem2 = data2.find((item) => item.data === inputValue);
            setSpecificItem2(foundItem2);
            const foundItem1 = data1.find((item) => item.data === inputValue);
            setSpecificItem1(foundItem1);

            if (specificItem === undefined || specificItem1 === null) {
                setCafeaChart(0)
                setLapteChart(0)
                setAlternativChart(0)
                setCashChart(0)
                setCardChart(0)
                console.log("Este null batistei")
                setBonuri(0)
            }

            else {
                setCafeaChart(specificItem.cafea)
                setLapteChart(specificItem.lapte)
                setAlternativChart(specificItem.lapte_alternativ)
                setCashChart(specificItem.cash)
                setCardChart(specificItem.card)
                setBonuri(specificItem.bonuri)
            }

            if (specificItem1 === undefined || specificItem1 === null) {
                setCafeaChart1(0)
                setLapteChart1(0)
                setAlternativChart1(0)
                setCashChart1(0)
                setCardChart1(0)
                console.log("Este null italiana")
                setBonuri1(0)
            }

            else{
                setCafeaChart1(specificItem1.cafea)
                setLapteChart1(specificItem1.lapte)
                setAlternativChart1(specificItem1.lapte_alternativ)
                setCashChart1(specificItem1.cash)
                setCardChart1(specificItem1.card)
                setBonuri1(specificItem1.bonuri)
            }

            if (specificItem2 === undefined || specificItem2 === null) {
                setCafeaChart2(0)
                setLapteChart2(0)
                setAlternativChart2(0)
                setCashChart2(0)
                setCardChart2(0)
                console.log("Este null lahovari")
                setBonuri2(0)
            }

            else {
                setCafeaChart2(specificItem2.cafea)
                setLapteChart2(specificItem2.lapte)
                setAlternativChart2(specificItem2.lapte_alternativ)
                setCashChart2(specificItem2.cash)
                setCardChart2(specificItem2.card)
                setBonuri2(specificItem2.bonuri)
            }
                console.log(cafeaChart)
                console.log(cafeaChart1)
                console.log(cafeaChart2)
                setCafeaChartTot((parseInt(cafeaChart)+parseInt(cafeaChart1)+parseInt(cafeaChart2)))
                setLapteChartTot((parseFloat(lapteChart)+parseFloat(lapteChart1)+parseFloat(lapteChart2)))
                setAlternativChartTot((parseFloat(alternativChart)+parseFloat(alternativChart1)+parseFloat(alternativChart2)))
                setCashChartTot((parseInt(cashChart)+parseInt(cashChart1)+parseInt(cashChart2)))
                setCardChartTot((parseInt(cardChart)+parseInt(cardChart1)+parseInt(cardChart2)))
                setBonuriTotal((parseInt(bonuri)+parseInt(bonuri1)+parseInt(bonuri2)))

        }

    };

    const handleSubmit2 = (event) => {
        event.preventDefault();
        console.log("submited " + selectedOption2.toString())
        const index = zile[luni.findIndex((element) => element === selectedOption2)];
        console.log(index);
        let arrChart = [];
        let arrDates = [];
        let arrChart1 = [];
        let arrChart2 = [];
        let arrChart3 = [];
        let arrDates1 = [];
        let arrTotal = [];


        let luna_start = luni.findIndex((element) => element === selectedOption2)
        let luna_end = luni.findIndex((element) => element === selectedOption4)
        let dif = luna_end - luna_start + 1
        console.log("DIFERENTA DE LUNI ESTE: " + dif)

        let foundItem = 0;
        let foundItem1 = 0;
        let foundItem2 = 0;
        let sumCafea = 0
        let sumLapte = 0
        let sumCash = 0;
        let sumCard = 0;
        let sumTotal = 0;

        for(let m=luna_start; m<=luna_end; m++) {

            for (let i = 1; i <= index; i++) {
                arrDates.push(i)
                if (i < 10) {
                    let calendar = inputValue1.toString() + "-" + luni_str[m].toString() + "-0" + i.toString()
                    // console.log("MAUSSSS " + calendar)
                    if (selectedOption1 == 'Incasari Batistei') {
                        foundItem = data.find((item) => item.data == calendar);
                    } else if (selectedOption1 == 'Incasari Italiana') {
                        foundItem = data1.find((item) => item.data == calendar);
                    } else if (selectedOption1 == 'Incasari Lahovari') {
                        foundItem = data2.find((item) => item.data == calendar);
                    } else if (selectedOption1 == 'Incasari Tot') {

                        foundItem = data.find((item) => item.data === calendar);
                        foundItem2 = data2.find((item) => item.data === calendar);
                        foundItem1 = data1.find((item) => item.data === calendar);

                        sumCash = 0
                        sumCard = 0
                        sumTotal = 0
                        sumLapte = 0
                        sumCafea = 0

                        if (foundItem === undefined) {
                            sumCash += 0
                            sumCard += 0
                            sumTotal += 0
                            sumLapte += 0
                            sumCafea += 0
                            console.log("Este null batistei")
                        }
                        else {
                            sumCash += parseInt(foundItem.cash)
                            sumCard += parseInt(foundItem.card)
                            sumLapte += parseInt(foundItem.lapte)
                            sumCafea += parseInt(foundItem.cafea)
                            sumTotal += parseInt(foundItem.card) + parseInt(foundItem.card)
                        }

                         if (foundItem1 === undefined) {
                             sumCash += 0
                             sumCard += 0
                             sumTotal += 0
                             sumLapte += 0
                             sumCafea += 0
                             console.log("Este null Italiana")
                         }
                         else {
                            sumCash += parseInt(foundItem1.cash)
                            sumCard += parseInt(foundItem1.card)
                             sumLapte += parseInt(foundItem1.lapte)
                             sumCafea += parseInt(foundItem1.cafea)
                            sumTotal += parseInt(foundItem1.card) + parseInt(foundItem1.card)
                        }

                        if (foundItem2 === undefined) {
                            sumCash += 0
                            sumCard += 0
                            sumTotal += 0
                            sumLapte += 0
                            sumCafea += 0
                            console.log("Este null Italiana")
                        } else {
                            sumCash += parseInt(foundItem2.cash)
                            sumCard += parseInt(foundItem2.card)
                            sumLapte += parseInt(foundItem2.lapte)
                            sumCafea += parseInt(foundItem2.cafea)
                            sumTotal += parseInt(foundItem2.card) + parseInt(foundItem2.card)
                        }

                        // arrChart.push(parseInt(foundItem.cash) + parseInt(foundItem1.cash) + parseInt(foundItem2.cash))
                        // arrChart1.push(parseInt(foundItem.card) + parseInt(foundItem1.card) + parseInt(foundItem2.card))
                        // arrTotal.push(parseInt(foundItem.card) + parseInt(foundItem.cash) + parseInt(foundItem1.card) + parseInt(foundItem1.cash) + parseInt(foundItem2.card) + parseInt(foundItem2.cash))

                        console.log(parseInt(cashChart) + parseInt(cashChart1) + parseInt(cashChart2))
                        arrChart.push(sumCash)
                        arrChart1.push(sumCard)
                        arrChart2.push(sumCafea)
                        arrChart3.push(sumLapte)
                        arrTotal.push(sumTotal)
                    }


                    if (foundItem != null && selectedOption1 != 'Incasari Tot') {
                        console.log(parseInt(foundItem.cash))
                        arrChart.push(parseInt(foundItem.cash))
                        arrChart1.push(parseInt(foundItem.card))
                        arrChart2.push(parseInt(foundItem.cafea))
                        arrChart3.push(parseInt(foundItem.lapte))
                        arrTotal.push(parseInt(foundItem.card) + parseInt(foundItem.cash))

                    } else if (foundItem == null && selectedOption1 != 'Incasari Tot') {
                        arrChart.push(0)
                        arrChart1.push(0)
                        arrChart2.push(0)
                        arrChart3.push(0)
                        arrTotal.push(0)
                    }
                } else {
                    let calendar = inputValue1.toString() + "-" + luni_str[m].toString() + "-" + i.toString()
                    // console.log("MAUSSSS " + calendar)
                    if (selectedOption1 == 'Incasari Batistei') {
                        foundItem = data.find((item) => item.data == calendar);
                    } else if (selectedOption1 == 'Incasari Italiana') {
                        foundItem = data1.find((item) => item.data == calendar);
                    } else if (selectedOption1 == 'Incasari Lahovari') {
                        foundItem = data2.find((item) => item.data == calendar);
                    } else if (selectedOption1 == 'Incasari Tot') {

                        foundItem = data.find((item) => item.data === calendar);
                        foundItem2 = data2.find((item) => item.data === calendar);
                        foundItem1 = data1.find((item) => item.data === calendar);

                        sumCash = 0
                        sumCard = 0
                        sumTotal = 0
                        sumCafea = 0
                        sumLapte = 0

                        if (foundItem === undefined) {
                            sumCash += 0
                            sumCard += 0
                            sumTotal += 0
                            sumCafea += 0
                            sumLapte += 0
                            console.log("Este null batistei")
                        }
                        else {
                            sumCash += parseInt(foundItem.cash)
                            sumCard += parseInt(foundItem.card)
                            sumCafea += parseInt(foundItem.cafea)
                            sumLapte += parseInt(foundItem.lapte)
                            sumTotal += parseInt(foundItem.card) + parseInt(foundItem.card)
                        }

                        if (foundItem1 === undefined) {
                            sumCash += 0
                            sumCard += 0
                            sumTotal += 0
                            sumCafea += 0
                            sumLapte += 0
                            console.log("Este null Italiana")
                        } else {
                            sumCash += parseInt(foundItem1.cash)
                            sumCard += parseInt(foundItem1.card)
                            sumCafea += parseInt(foundItem1.cafea)
                            sumLapte += parseInt(foundItem1.lapte)
                            sumTotal += parseInt(foundItem1.card) + parseInt(foundItem1.card)
                        }

                        if (foundItem2 === undefined) {
                            sumCash += 0
                            sumCard += 0
                            sumTotal += 0
                            sumCafea += 0
                            sumLapte += 0
                            console.log("Este null Italiana")
                        } else {
                            sumCash += parseInt(foundItem2.cash)
                            sumCard += parseInt(foundItem2.card)
                            sumCafea += parseInt(foundItem2.cafea)
                            sumLapte += parseInt(foundItem2.lapte)
                            sumTotal += parseInt(foundItem2.card) + parseInt(foundItem2.card)
                        }


                        arrChart.push(sumCash)
                        arrChart1.push(sumCard)
                        arrChart2.push(sumCafea)
                        arrChart3.push(sumLapte)
                        arrTotal.push(sumTotal)
                        console.log("Suma totala" + sumTotal)
                    }

                    if (foundItem != null && selectedOption1 != 'Incasari Tot') {
                        let totLog = parseInt(foundItem.card) + parseInt(foundItem.cash)
                        console.log("Total pe data: " + calendar + " este: \n" + totLog)

                        arrChart.push(parseInt(foundItem.cash))
                        arrChart1.push(parseInt(foundItem.card))
                        arrChart2.push(parseInt(foundItem.cafea))
                        arrChart3.push(parseInt(foundItem.lapte))
                        arrTotal.push(parseInt(foundItem.card) + parseInt(foundItem.cash))

                    } else if (foundItem == null && selectedOption1 != 'Incasari Tot') {
                        arrChart.push(0)
                        arrChart1.push(0)
                        arrChart2.push(0)
                        arrChart3.push(0)
                        arrTotal.push(0)
                    }

                }
            }

            console.log("\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\")
            console.log(arrChart)
            console.log(arrChart1)
            console.log(arrChart2)
            console.log(arrChart3)
            const sumCafeaFinal = arrChart2.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0);
            const sumLapteFinal = arrChart3.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0);
            setSumMonthCafea(sumCafeaFinal)
            setSumMonthLapte(sumLapteFinal)
            console.log(sumCafea)
            console.log(sumLapte)
            console.log("\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\")


            arrDates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                25, 26, 27, 28, 29, 30, 31]

            let newArray = Array(31).fill(0);
            let cnt = 0

            for (let j = 0; j <= (luna_end - luna_start); j++) {
                for (let i = 0; i < 31; i++) {
                    newArray[i] = newArray[i] + arrChart[i+cnt]
                }
                cnt = cnt + 31
            }

            let newArray2 = Array(31).fill(0);
            let cnt2 = 0

            for (let j = 0; j <= (luna_end - luna_start); j++) {
                for (let i = 0; i < 31; i++) {
                    newArray2[i] = newArray2[i] + arrChart1[i+cnt2]
                }
                cnt2 = cnt2 + 31
            }

            let newArray3 = Array(31).fill(0);
            let cnt3 = 0

            for (let j = 0; j <= (luna_end - luna_start); j++) {
                for (let i = 0; i < 31; i++) {
                    newArray3[i] = newArray3[i] + arrTotal[i+cnt3]
                }
                cnt3 = cnt3 + 31
            }
            console.log("VIRGIILLLLL" + newArray3)
            console.log(arrChart)
            console.log(arrDates)
            setX(arrDates)
            setX1(arrDates)
            setY(newArray)
            setY1(newArray2)
            setTotalVanzari((arrTotal))
            console.log("Total Vanzari1: " + newArray)
            console.log("Total Vanzari2: " + newArray2)
            console.log("Total Vanzari3: " + arrTotal)
            const arrTotalSum = arrChart.map((value, index) => value + arrChart1[index]);
            setSumMonthCash(arrChart.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
            setSumMonthCard(arrChart1.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
            setSumMonth(arrTotalSum.reduce((accumulator, currentValue) => accumulator + currentValue, 0))

        }
    };

    trace3.x = x
    trace3.y = y
    trace4.x = x1
    trace4.y = y1
    trace5.x = x
    trace5.y = totalVanzari


    const generatePDF = (orders) => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('Lista Comanda', 10, 10);

        let y = 15;
        const pageHeight = doc.internal.pageSize.height - 20; // Adjust based on your content and margin

        orders.forEach(order => {
            const comanda = JSON.parse(order.comanda);
            const orderHeight = (Object.keys(comanda).filter(productName => comanda[productName].value !== 0).length + 3) * 10;

            if (y + 30 > pageHeight) {
                doc.addPage(); // Start a new page if there's not enough space
                y = 10;
            }

            y += 15;
            doc.text(`Locatie: ${order.locatie}`, 10, y);
            doc.text(`ID Comanda: ${order.ID}`, 10, y += 10);
            doc.text(`Data: ${order.data}`, 10, y += 10);
            y += 15;

            Object.keys(comanda).forEach(productName => {
                const product = comanda[productName];
                if (product.value !== 0) {
                    const urgentText = product.urgent !== '' ? `(${product.urgent})` : '';
                    const productText = `${productName}: ${product.value} ${urgentText}`; // Add [ ] for checkbox
                    doc.text(productText, 20, y += 7);

                    // Add checkbox
                    doc.setLineWidth(0.5);
                    doc.rect(12, y - 5, 3, 3);
                    doc.stroke();

                    if (y > pageHeight) {
                        doc.addPage(); // Start a new page if there's not enough space
                        y = 7;
                    }
                }
            });
        });

        doc.save('comenzi.pdf');
    };



    const generateAggregatedPDF = (aggregatedOrders) => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('Toate Comenzile', 10, 10);

        let y = 20;
        const pageHeight = doc.internal.pageSize.height - 10; // Adjust based on your content and margin

        doc.text(`Start Date:  ${inputValue2} `, 10, y+=15);
        doc.text(`End Date:  ${inputValue3} `, 10, y+=15);

        y += 10;

        aggregatedOrders.forEach(order => {
            const orderHeight = (order.urgent !== "URGENT" ? 4 : 3) * 10; // Height for the order details

            if (y  + orderHeight > pageHeight) {
                doc.addPage(); // Start a new page if there's not enough space
                y = 30;
            }

            if (order.urgent === "URGENT") {
                doc.text(`${order.name} ${order.value} ${order.urgent}`, 10, y += 7);
            }
            else {
                doc.text(`${order.name} ${order.value}`, 10, y += 7);
            }
        });

        doc.save('toate_comenzile.pdf');
    };





    const filterOrdersByDate = () => {
        const startDate = new Date(inputValue2);
        const endDate = new Date(inputValue3);

        const filteredOrders = data3.filter(order => {
            const orderDate = new Date(order.data);
            const location = "Comenzi " + order.locatie;
            return orderDate >= startDate && orderDate <= endDate && location === selectedOption3;
        });

        generatePDF(filteredOrders);



    };


    const filterOrdersByDateAll = () => {
        const startDate = new Date(inputValue2);
        const endDate = new Date(inputValue3);

        const filteredOrders = data3.filter(order => {
            const orderDate = new Date(order.data);
            const location = "Comenzi " + order.locatie;
            return orderDate >= startDate && orderDate <= endDate;
        });

        generatePDF(filteredOrders);

    };


    const startDate = new Date(inputValue2);
    const endDate = new Date(inputValue3);

    const filteredOrders = data3.filter(order => {
        const orderDate = new Date(order.data);
        return orderDate >= startDate && orderDate <= endDate;
    });


    const aggregatedData = {}; // Initialize an empty object

    filteredOrders.forEach(item => {
        const comanda = JSON.parse(item.comanda);
        Object.keys(comanda).forEach(key => {
            const product = comanda[key];
            if (product.value !== 0) {
                if (!aggregatedData[key]) {
                    aggregatedData[key] = {
                        value: 0,
                        urgent: '',
                    };
                }
                aggregatedData[key].value += parseInt(product.value, 10); // Parse as integer
                if (product.urgent === 'URGENT') {
                    aggregatedData[key].urgent = 'URGENT';
                }
            }
        });
    });


    const aggregatedOrders = Object.keys(aggregatedData).map(key => ({
        name: key,
        value: aggregatedData[key].value,
        urgent: aggregatedData[key].urgent
    }));


    let showAggregatedOrders = true;


    const shouldShowButton = selectedOption3 === "Comenzi Tot"
    const showButton =
        selectedOption3 === "Comenzi Batistei" ||
        selectedOption3 === "Comenzi Italiana" ||
        selectedOption3 === "Comenzi Lahovari";

    return(
        <div className="stats">
            <Navbar/>
            <div className="chart-row">
                <div className="option-btn">
                    <DropdownButton
                        options={options}
                        selectedOption={selectedOption}
                        onSelect={handleOptionSelect}
                    />
                </div>
                <div>
                    <form onSubmit={handleSubmit} >
                        <br/>
                        <input
                            style={{padding: "4px 20px"}}
                            className="input-year-date"
                            type="date"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="Enter a value"
                        />
                        <button type="submit" className="submit-btn" >Submit</button>
                    </form>



                    {specificItem && (
                        <div>
                            <div className="center-first">
                                <div className="center-info-first">
                            <br/>
                                    <div style={{padding: "2px"}}>Data: {specificItem.data}</div>
                                    <div style={{padding: "2px"}}>Cafea: {cafeaChartTot}</div>
                                    <div style={{padding: "2px"}}>Lapte: {lapteChartTot}</div>
                                    <div style={{padding: "2px"}}>Alternativ: {alternativChartTot}</div>
                                    <div style={{padding: "2px"}}>Cash: {cashChartTot}</div>
                                    <div style={{padding: "2px"}}>Card: {cardChartTot}</div>
                                    <div style={{padding: "2px"}}>Bonuri: {bonuriTotal}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <br/>
                <br/>
                <div className="chart-row" style={{marginTop: "200px", marginBottom: "-100px"}}>
                    <Plot
                        data={[
                            {
                                values: [cashChartTot, cardChartTot],
                                labels: labels1,
                                type: 'pie',
                                // text: labels1.map((label, index) => `${label}: ${cashAndCard[1]} `),
                                text: [cashChartTot, cardChartTot],
                                textposition: 'inside',
                                hoverinfo: 'text',
                                marker: {
                                    colors: ['green', 'blue'], // Set custom colors for the pie slices
                                },
                            },
                        ]}
                        layout={{ width: 600, height: 450, title:
                             inputValue + "\n"
                            + "Total Cash: " +  cashChartTot + "\n"
                            + "Total Card: " +  cardChartTot + "\n"
                            + "Total Incasari: " +  (parseInt(cardChartTot)+parseInt(cashChartTot)).toString() + "\n",
                    }}
                    />
            </div>
            </div>
                {/*) :*/}
            <div className="chart-row">
                <div className="center-formulas-formulas">
                    <div className="center-info-formulas">
                        <br/>
                        <p>Incasari/Lapte: {((parseInt(cashChartTot)+parseInt(cardChartTot))/(parseInt(lapteChartTot)+parseInt(alternativChartTot))).toFixed(2)}</p>
                        <br/>
                        <p>Incasari/Cafea - 4%: {((parseInt(cashChartTot)+parseInt(cardChartTot))/((parseInt(cafeaChartTot)/8)*0.96)).toFixed(2)}</p>
                        <br/>
                        <p>Card/Bonuri: {(parseInt(cardChartTot)/(parseInt(bonuriTotal))).toFixed(2)}</p>
                        <br/>
                    </div>
                </div>
                {/*<Plot*/}
                {/*    data={[*/}
                {/*        {*/}
                {/*            values: [(parseInt(cashChart)+parseInt(cardChart))/(parseInt(lapteChart)+parseInt(alternativChart)), (parseInt(cashChart)+parseInt(cardChart))/(parseInt(cafeaChart)/8)*0.96],*/}
                {/*            labels: labels2 ,*/}
                {/*            type: 'pie'*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*    layout={ {width: 500, height: 450, title: "Formula " + dataChart.toString()} }*/}
                {/*/>*/}
                {/*}*/}
            </div>
                <div className="chart-row">

                    <form onSubmit={handleSubmit2}>
                        <div className="option-btn">
                            <DropdownButton
                                options={options1}
                                selectedOption={selectedOption1}
                                onSelect={handleOptionSelect1}
                            />
                        </div>
                        <div className="option-btn">
                            De la
                            <DropdownButton
                                options={options2}
                                selectedOption={selectedOption2}
                                onSelect={handleOptionSelect2}
                            />
                            pana la
                            <DropdownButton
                                options={options4}
                                selectedOption={selectedOption4}
                                onSelect={handleOptionSelect4}
                            />
                        </div>
                        <input
                            className="input-year"
                            type="text"
                            value={inputValue1}
                            onChange={handleInputChange1}
                            placeholder="Year"
                        />
                        <button type="submit" className="submit-btn">Submit</button>
                    </form>

                   <div className="center-formulas">
                    <div className="center-info">
                        <br/>
                        <p>Total Cafea: {sumMonthCafea}</p>
                        <p>Total Lapte: {sumMonthLapte}</p>
                        <p>Total Cash: {sumMonthCash}</p>
                        <p>Total Card: {sumMonthCard}</p>
                        <p>Total Incasari: {sumMonth}</p>
                        <br/>
                    </div>
                   </div>

                    <Plot

                        data = { [trace3, trace4]}

                        layout = {{
                            title:  'Vanzari ' + selectedOption2 + " - " + selectedOption4 + " " + inputValue1 + "\n"
                                + "Total Cash: " +  sumMonthCash + "\n"
                                + "Total Card: " +  sumMonthCard + "\n"
                                + "Total: " +  sumMonth + "\n",
                            xaxis: {
                                tickangle: -45
                            },
                            barmode: 'group',

                        }}
                    />

                    <Plot

                        data = { [trace5]}

                        layout = {{
                            title:  'Vanzari ' + selectedOption2 + " - " + selectedOption4 + " " + inputValue1 + "\n"
                                + "Total Cash: " +  sumMonthCash + "\n"
                                + "Total Card: " +  sumMonthCard + "\n"
                                + "Total: " +  sumMonth + "\n",
                            xaxis: {
                                tickangle: -45
                            },
                            barmode: 'group',

                        }}
                    />
                </div>


            {/*#####################################*/}
            {/*#####################################*/}
            {/*#####################################*/}


            <div className="chart-row">

                <form onSubmit={handleSubmit2}>
                    <div className="option-btn">
                        <DropdownButton
                            options={options3}
                            selectedOption={selectedOption3}
                            onSelect={handleOptionSelect3}
                        />
                    </div>
                    <input
                        className="input-year"
                        type="date"
                        value={inputValue2}
                        onChange={handleInputChange2}
                        placeholder="Start Date"
                    />
                    <input
                        className="input-year1"
                        type="date"
                        value={inputValue3}
                        onChange={handleInputChange3}
                        placeholder="End Date"
                    />
                    <br/>
                    <br/>
                    <p>Ati ales comenzile</p>
                    <p>de la {inputValue2} pana la {inputValue3}</p>
                    <br />
                    <br/>
                    <ul>
                        {data3.map(item => {
                            const comanda = JSON.parse(item.comanda);

                            // Filter out properties with value not equal to 0 or "0"
                            const filteredComanda = {};
                            for (const key in comanda) {
                                const valueObj = comanda[key];
                                if (valueObj.value !== 0 && valueObj.value !== "0") {
                                    filteredComanda[key] = valueObj;
                                }
                            }

                            // Check if the filtered comanda object is empty
                            const isComandaEmpty = Object.keys(filteredComanda).length === 0;


                            const itemDate = new Date(item.data);
                            const inputDate2 = new Date(inputValue2);
                            const inputDate3 = new Date(inputValue3);
                            const location = "Comenzi " + item.locatie;
                            console.log(location)

                            // Check if the item's date falls within the desired range
                            const isDateInRange = itemDate >= inputDate2 && itemDate <= inputDate3;

                            // Display the item only if the filtered comanda is not empty and the date is within range
                            if (!isComandaEmpty && isDateInRange && location === selectedOption3) {
                                return (
                                    <div>
                                    <div>
                                    <li key={item.ID} className="comanda-id">
                                        <br/>
                                        <p> Comanda cu ID: {item.ID}</p>
                                        <p> Data: {item.data}</p>
                                        <p> Cafenea: {item.locatie}</p>
                                        <br/>
                                        <p>
                                            {Object.keys(filteredComanda).map((key, index) => (
                                                <div key={key} style={{textAlign: "left", marginLeft: "8%"}}>
                                                    <p className={filteredComanda[key].urgent === 'URGENT' ? 'urgent' : ''}>
                                                        {key}{' '}
                                                        <input className="input-comanda" readOnly value={filteredComanda[key].value} />{' '}
                                                        {filteredComanda[key].urgent === 'URGENT' ? <span className="urgent-text">(URGENT)</span> : ''}
                                                    </p>
                                                    <br/>
                                                </div>
                                            ))}
                                        </p>
                                        <button className="btn-del" onClick={() => handleDeleteOrder(item.ID)}>Delete</button>
                                        <br/>
                                    </li>
                                        <br/>
                                    </div>
                                {/*<button className="pdf-down" onClick={filterOrdersByDateAll}>Download PDF</button>*/}
                                    </div>
                                );
                            }

                            if (
                                !isComandaEmpty &&
                                isDateInRange &&
                                selectedOption3 === "Comenzi Tot"
                            ) {
                                return (
                                    <div>
                                        <div>
                                            {showAggregatedOrders && aggregatedOrders.length > 0 && (
                                                <div className="aggregated-orders all-orders">
                                                    <br/>
                                                    <h2>Toate Comenzile</h2>
                                                    <br/>
                                                    {aggregatedOrders.map(order => (
                                                        <div
                                                            key={order.name}
                                                            style={{ textAlign: "left", marginLeft: "8%" }}
                                                        >
                                                            <p className={order.urgent === "URGENT" ? "urgent" : ""}>
                                                                {order.name}{" "}
                                                                <input
                                                                    className="input-comanda"
                                                                    readOnly
                                                                    value={order.value}
                                                                />{" "}
                                                                {order.urgent === "URGENT" ? (
                                                                    <span className="urgent-text">(URGENT)</span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </p>
                                                            <br />
                                                        </div>
                                                    ))}
                                                    <br/>
                                                    <button className="pdf-down" onClick={() => generateAggregatedPDF(aggregatedOrders)}>Download PDF</button>
                                                    <br/>
                                                    <br/>
                                                </div>
                                            )}
                                            {showAggregatedOrders && (showAggregatedOrders = false)}
                                        </div>
                                        <div>
                                            <li key={item.ID} className="comanda-id">
                                                <br/>
                                                <p> Comanda cu ID: {item.ID}</p>
                                                <p> Data: {item.data}</p>
                                                <p> Cafenea: {item.locatie}</p>
                                                <br/>
                                                <p>
                                                    {Object.keys(filteredComanda).map((key, index) => (
                                                        <div key={key} style={{textAlign: "left", marginLeft: "8%"}}>
                                                            <p className={filteredComanda[key].urgent === 'URGENT' ? 'urgent' : ''}>
                                                                {key}{' '}
                                                                <input className="input-comanda" readOnly value={filteredComanda[key].value} />{' '}
                                                                {filteredComanda[key].urgent === 'URGENT' ? <span className="urgent-text">(URGENT)</span> : ''}
                                                            </p>
                                                            <br/>
                                                        </div>
                                                    ))}
                                                </p>
                                                <button className="btn-del" onClick={() => handleDeleteOrder(item.ID)}>Delete</button>
                                                <br/>
                                            </li>
                                            <br/>
                                        </div>
                                        {/*<button className="pdf-down" onClick={filterOrdersByDateAll}>Download PDF</button>*/}
                                    </div>
                                );
                            }



                            return null; // Skip items that don't meet the criteria
                        })}


                    </ul>
                    {shouldShowButton && (
                        <button className="pdf-down" onClick={filterOrdersByDateAll}>
                            Download PDF
                        </button>
                    )}
                    {showButton && (
                        <button className="pdf-down" onClick={filterOrdersByDate}>
                            Download PDF
                        </button>
                    )}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </form>
            </div>

        </div>
    )
}